// Libraries
import React, { Component } from 'react';

class RunningGroupsFormPage extends Component {
    // Note: Use class-based component because it will have state in the future
    // once we add the form functionality
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='w-full p-4 mx-auto prose dark:prose-invert'>
                <h2>Add Running Group</h2>
                <p>Add your running group to our list!</p>
                <p>
                    To add a group, email{' '}
                    <a href='mailto:webmaster@pprrun.org'>
                        webmaster@pprrun.org
                    </a>{' '}
                    with the following group information:
                </p>
                <ul>
                    <li>Name</li>
                    <li>Day(s) &amp; Time(s)</li>
                    <li>Frequency (e.g. Year-Round, June through August)</li>
                    <li>Distance / Time / Duration</li>
                    <li>Location</li>
                    <li>Location Link (e.g. Google Maps)</li>
                    <li>
                        Description - include information about pace, types of
                        runs, terrain, et cetera
                    </li>
                    <li>Relevant Links (e.g. Facebook Group, website)</li>
                    <li>Group Photo (strongly recommended)</li>
                    <li>Contact Name</li>
                    <li>Contact Email and / or Phone</li>
                </ul>
                <p>
                    We will do our best to review and post your group within one
                    week.
                </p>
            </div>
        );
    }
}

export default RunningGroupsFormPage;
