const { isNullUndefined } = require('./general');

const getPhotoPositionClass = (photoPosition) => {
    let photoPositionClass = '';

    switch (photoPosition) {
        case 'top':
            photoPositionClass = 'bg-top';
            break;

        case 'bottom':
            photoPositionClass = 'bg-bottom';
            break;

        case 'center':
            photoPositionClass = 'bg-center';
            break;

        case 'left':
            photoPositionClass = 'bg-left';
            break;

        case 'left_bottom':
            photoPositionClass = 'bg-left-bottom';
            break;

        case 'left_top':
            photoPositionClass = 'bg-left-top';
            break;

        case 'right':
            photoPositionClass = 'bg-right';
            break;

        case 'right_bottom':
            photoPositionClass = 'bg-right-bottom';
            break;

        case 'right_top':
            photoPositionClass = 'bg-right-top';
            break;

        default:
            break;
    }

    return photoPositionClass;
};

const getBackgroundGradientClass = (media, id) => {
    if (isNullUndefined(media)) {
        if ((id + 1) % 4 === 1) {
            return 'bg-gradient-to-tr from-red to-red-light';
        }
        if ((id + 1) % 4 === 2) {
            return 'bg-gradient-to-tr from-green to-green-light';
        }
        if ((id + 1) % 4 === 3) {
            return 'bg-gradient-to-tr from-blue to-blue-light';
        }
        return 'bg-gradient-to-tr from-yellow to-yellow-light';
    }
    return '';
};

module.exports = {
    getPhotoPositionClass,
    getBackgroundGradientClass,
};
