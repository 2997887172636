export const setInitialTheme = () => {
    return { type: 'SET_INITIAL_THEME' };
};

export const setTheme = (theme) => {
    return { type: 'SET_THEME', theme };
};

export const setScrollPosition = (scrollPosition, page) => {
    return { type: 'SET_SCROLL_POSITION', scrollPosition, page };
};

export const setResults = (results) => {
    return { type: 'SET_RESULTS', results };
};

export const setCourseRecords = (courseRecords) => {
    return { type: 'SET_COURSE_RECORDS', courseRecords };
};

export const setPersonalCourseRecords = (personalCourseRecords) => {
    return { type: 'SET_PERSONAL_COURSE_RECORDS', personalCourseRecords };
};

export const setMonthDisclosures = (monthDisclosures) => {
    return { type: 'SET_MONTH_DISCLOSURES', monthDisclosures };
};

export const setSelectedYear = (selectedYear) => {
    return { type: 'SET_SELECTED_YEAR', selectedYear };
};

export const setSponsors = (sponsors) => {
    return { type: 'SET_SPONSORS', sponsors };
};
