const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getEvents = (startDate, endDate) => {
    const query = qs.stringify({
        filters: {
            date_time: {
                $gte: startDate,
                $lte: endDate,
            },
        },
        sort: ['date_time:asc'],
        populate: ['link', 'contacts'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/events?${query}`,
    });
};

const getEvent = (eventId) => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/events/${eventId}`,
    });
};

const getLatestEvent = () => {
    const query = qs.stringify({
        pagination: {
            limit: 1,
        },
        sort: ['date_time:desc'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/events?${query}`,
    });
};

module.exports = {
    getEvents,
    getEvent,
    getLatestEvent,
};
