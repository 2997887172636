const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getResults = (year) => {
    const query = qs.stringify({
        filters: {
            race_date: {
                $gte: `${year}-01-01`,
                $lte: `${year}-12-31`,
            },
        },
        sort: ['race_date:desc', 'link_name:asc'],
        fields: [
            'link',
            'credit',
            'result_id',
            'event_id',
            'race_date',
            'link_name',
            'event_name',
            'division_id',
            'is_stats',
        ],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/results?${query}`,
    });
};

const getResultsForCalendarEvent = (eventId) => {
    const query = qs.stringify({
        filters: {
            event: {
                id: {
                    $eq: eventId,
                },
            },
        },
        fields: [
            'id',
            'division_id',
            'is_stats',
            'result_id',
            'link_name',
            'link',
            'credit',
        ],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/results?${query}`,
    });
};

const getResult = (resultId) => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/results/${resultId}`,
    });
};

module.exports = {
    getResults,
    getResultsForCalendarEvent,
    getResult,
};
