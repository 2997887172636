// Libraries
import { useState } from 'react';

// Components
import ResponsiveMedia from '../ResponsiveMedia/ResponsiveMedia';

// Services, data, and media
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const MediaCarousel = ({ media }) => {
    const [index, setIndex] = useState(0);

    const moveRight = () => {
        let newIndex;
        if (index >= media.length - 1) {
            newIndex = 0;
        } else {
            newIndex = index + 1;
        }
        setIndex(newIndex);
    };

    const moveLeft = () => {
        let newIndex;
        if (index === 0) {
            newIndex = media.length - 1;
        } else {
            newIndex = index - 1;
        }
        setIndex(newIndex);
    };

    if (media.length > 0) {
        return (
            <>
                <div className='not-prose relative flex justify-between items-end'>
                    <ResponsiveMedia
                        media={media[index]}
                        classes='w-full rounded-xl'
                    />
                    {media.length > 1 ? (
                        <>
                            <div
                                className='absolute cursor-pointer left-0 bg-gradient-to-tr from-black rounded-bl-xl'
                                onClick={moveRight}
                            >
                                <ChevronLeftIcon className='h-12 w-12 heroicon-stroke-w-2 text-gray-50' />
                            </div>
                            <div
                                className='absolute cursor-pointer right-0 bg-gradient-to-tl from-black rounded-br-xl'
                                onClick={moveLeft}
                            >
                                <ChevronRightIcon className='h-12 w-12 heroicon-stroke-w-2 text-gray-50' />
                            </div>
                        </>
                    ) : null}
                </div>
                <figcaption className='mt-8'>
                    {media[index].attributes.caption}
                </figcaption>
            </>
        );
    }
    return null;
};

export default MediaCarousel;
