import { createTheme } from 'react-data-table-component';

const lightTheme = {
    text: {
        primary: '#111827',
        secondary: '#374151',
        disabled: '#6b7280',
    },
    background: {
        default: '#f9fafb',
    },
    context: {
        background: '#f3f4f6',
        text: '#111827',
    },
    divider: {
        default: '#d1d5db',
    },
    button: {
        default: '#111827',
        focus: '#d1d5db',
        hover: '#d1d5db',
        disabled: '#6b7280',
    },
    selected: {
        default: '#d1d5db',
        text: '#111827',
    },
    highlightOnHover: {
        default: '#d1d5db',
        text: '#111827',
    },
    striped: {
        default: '#f3f4f6',
        text: '#111827',
    },
};

const darkTheme = {
    text: {
        primary: '#f9fafb',
        secondary: '#d1d5db',
        disabled: '#6b7280',
    },
    background: {
        default: '#111827',
    },
    context: {
        background: '#1f2937',
        text: '#f9fafb',
    },
    divider: {
        default: '#374151',
    },
    button: {
        default: '#f9fafb',
        focus: '#374151',
        hover: '#374151',
        disabled: '#6b7280',
    },
    selected: {
        default: '#374151',
        text: '#f9fafb',
    },
    highlightOnHover: {
        default: '#374151',
        text: '#f9fafb',
    },
    striped: {
        default: '#1f2937',
        text: '#f9fafb',
    },
};

createTheme('light', lightTheme);
createTheme('dark', darkTheme);

export const customStyles = {
    header: {
        style: {
            fontFamily: 'yorkten-slab-normal',
        },
    },
    headRow: {
        style: {
            fontFamily: 'yorkten-slab-normal',
        },
    },
    rows: {
        style: {
            fontSize: '0.8rem',
        },
        stripedStyle: {
            fontSize: '0.8rem',
        },
    },
    headCells: {
        style: {
            fontSize: '1rem',
        },
    },
};
