// Libraries
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

// Components
import LinkButton from '../../components/LinkButton/LinkButton';
import EventDetailResultsDisplay from './EventDetailResultsDisplay';
import ContactCard from './ContactCard';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import EventTypeIcons from './EventTypeIcons';

// Services, data, and media
import { getResultsForCalendarEvent } from '../../services/resultsService';
import { getEntryListsForCalendarEvent } from '../../services/entryListsService';
import { prettyDateTime } from '../../utils/datesAndTimes';
import { classNames } from '../../utils/general';
import {
    MapPinIcon,
    FlagIcon,
    CalendarIcon,
} from '@heroicons/react/24/outline';
const gfm = require('remark-gfm');

class EventDetail extends Component {
    state = {
        loading: true,
        error: false,
        results: [],
        entryLists: [],
    };

    componentDidMount() {
        const { data } = this.props;
        Promise.all([
            getResultsForCalendarEvent(data.id),
            getEntryListsForCalendarEvent(data.id),
        ])
            .then((responses) => {
                this.setState({
                    loading: false,
                    results: responses[0].data?.data,
                    entryLists: responses[1].data?.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    render() {
        const { results, entryLists, loading, error } = this.state;
        const { data } = this.props;

        if (loading) {
            return <LoadingSpinner classes='flex justify-center p-4' />;
        }

        if (error) {
            return (
                <div className='p-4'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        if (data?.attributes) {
            const {
                cancelled,
                club_assisted_event,
                club_event,
                community_cup_event,
                contacts,
                date_time,
                description,
                distance,
                event_name,
                event_name_link,
                link,
                location,
                location_link,
            } = data.attributes;

            let eventName = null;
            if (event_name) {
                if (!event_name_link) {
                    eventName = <span>{event_name}</span>;
                } else if (event_name_link.includes('http')) {
                    // External link
                    eventName = (
                        <a
                            className='hover:underline'
                            href={event_name_link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {event_name}
                        </a>
                    );
                } else {
                    // Internal link
                    eventName = (
                        <Link to={event_name_link} className='hover:underline'>
                            {event_name}
                        </Link>
                    );
                }
            }

            let eventLocation = null;
            if (location_link) {
                if (location_link.includes('http')) {
                    // External
                    eventLocation = (
                        <a
                            className='hover:underline'
                            href={location_link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {location}
                        </a>
                    );
                } else {
                    // Internal
                    eventLocation = (
                        <Link to={location_link} className='hover:underline'>
                            {location}
                        </Link>
                    );
                }
            } else {
                eventLocation = <span>{location}</span>;
            }

            let contactsDiv = null;
            const contactsArr = contacts?.data.map((contact) => (
                <ContactCard key={contact.id} contact={contact} />
            ));
            if (contactsArr.length > 0) {
                contactsDiv = (
                    <div className='flex flex-wrap -ml-1 -mt-1'>
                        {contactsArr}
                    </div>
                );
            }

            let entryListLinkButton = null;
            if (entryLists.length > 0) {
                /* Take the first entry list, the most recent is first. In most cases
            there is only one entry list. */
                const entryList = entryLists[0];
                const entryListLink = {
                    color: 'green',
                    external: false,
                    link_text: 'Entry List',
                    url: `/entry-lists/${entryList?.attributes?.entry_list_id}`,
                };
                entryListLinkButton = <LinkButton link={entryListLink} />;
            }

            const linksArr = link.map((link) => (
                <LinkButton link={link} key={link.id} />
            ));

            return (
                <div className='p-4'>
                    {eventName ? (
                        <h4
                            className={classNames(
                                'font-serif font-bold mb-2',
                                cancelled
                                    ? 'line-through text-red'
                                    : 'no-underline',
                                club_event ||
                                    club_assisted_event ||
                                    community_cup_event
                                    ? 'flex items-center'
                                    : ''
                            )}
                        >
                            {eventName}
                            <EventTypeIcons
                                clubEvent={club_event}
                                clubAssistedEvent={club_assisted_event}
                                communityCupEvent={community_cup_event}
                            />
                        </h4>
                    ) : null}
                    {cancelled ? (
                        <h4 className='font-serif font-bold mb-2 text-red'>
                            CANCELLED
                        </h4>
                    ) : null}
                    <div className='md:grid md:grid-cols-2 md:gap-4'>
                        <div>
                            <div className='mb-2'>
                                {date_time ? (
                                    <div className='flex items-center my-1'>
                                        <div className='w-6 mr-2'>
                                            <CalendarIcon
                                                className='h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <span>
                                            {prettyDateTime(
                                                new Date(date_time)
                                            )}
                                        </span>
                                    </div>
                                ) : null}
                                {distance ? (
                                    <div className='flex items-center my-1'>
                                        <div className='w-6 mr-2'>
                                            <FlagIcon
                                                className='h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <span>{distance}</span>
                                    </div>
                                ) : null}
                                {location ? (
                                    <div className='flex items-center my-1'>
                                        <div className='w-6 mr-2'>
                                            <MapPinIcon
                                                className='h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        {eventLocation}
                                    </div>
                                ) : null}
                            </div>
                            {entryListLinkButton || linksArr.length > 0 ? (
                                <div className='flex flex-wrap items-start -ml-1 -mt-1 my-2'>
                                    {entryListLinkButton}
                                    {linksArr}
                                </div>
                            ) : null}
                            {contactsDiv ? (
                                <div className='my-2'>
                                    <h5 className='font-serif font-bold mb-1'>
                                        {contactsArr.length > 1
                                            ? 'Contacts'
                                            : 'Contact'}
                                    </h5>
                                    {contactsDiv}
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {description && description !== '' ? (
                                <>
                                    <h5 className='font-serif font-bold'>
                                        Event Description
                                    </h5>
                                    <div className='prose dark:prose-invert my-2'>
                                        <ReactMarkdown
                                            children={description}
                                            remarkPlugins={[gfm]}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <EventDetailResultsDisplay resultsData={results} />
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default EventDetail;
