// Libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';

// Services, data, and media
import { getMenuItems } from '../../services/navigationService';
import { currentYear } from '../../utils/datesAndTimes';
import pprrLogoFullColor from '../../assets/images/pprr-logos/pprr-logo-full-color.svg';
import pprrLogoFullWhite from '../../assets/images/pprr-logos/pprr-logo-full-white.svg';
import rrcaLogoBlue from '../../assets/images/rrca-logos/rrca-logo-blue.svg';
import rrcaLogoYellow from '../../assets/images/rrca-logos/rrca-logo-yellow.svg';
import facebookLogoDark from '../../assets/images/social-media-logos/facebook_gray-900.svg';
import facebookLogoLight from '../../assets/images/social-media-logos/facebook_gray-50.svg';
import instagramLogoDark from '../../assets/images/social-media-logos/instagram_gray-900.svg';
import instagramLogoLight from '../../assets/images/social-media-logos/instagram_gray-50.svg';
import pikesPeakTraceLight from '../../assets/images/footer/pikes-peak-gray-200.svg';
import pikesPeakTraceDark from '../../assets/images/footer/pikes-peak-gray-800.svg';

const Footer = ({ theme }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [menuItemsData, setMenuItemsData] = useState([]);

    useEffect(() => {
        getMenuItems()
            .then((response) => {
                setLoading(false);
                setMenuItemsData(response.data?.data);
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    }, []);

    const menuSections = menuItemsData.map((section) => {
        if (!section || !section.attributes) {
            return null;
        }
        const { sub_menu_items, name, external, slug } = section.attributes;
        let menuItems = [];
        if (sub_menu_items && sub_menu_items.data) {
            menuItems = sub_menu_items.data.map((item) => {
                if (!item || !item.attributes) {
                    return null;
                }
                const { external, slug, name } = item.attributes;
                if (external) {
                    return (
                        <li key={item.id}>
                            <a
                                className='hover:underline'
                                href={slug}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {name}
                            </a>
                        </li>
                    );
                }
                return (
                    <li key={item.id}>
                        <Link className='hover:underline' to={slug}>
                            {name}
                        </Link>
                    </li>
                );
            });
        }

        return (
            <div className='w-full' key={section.id}>
                {menuItems.length > 0 ? (
                    <h5 className='font-bold'>{name}</h5>
                ) : (
                    <h5 className='font-bold'>
                        {external ? (
                            <a
                                className='hover:underline'
                                href={slug}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {name}
                            </a>
                        ) : (
                            <Link className='hover:underline' to={slug}>
                                {name}
                            </Link>
                        )}
                    </h5>
                )}
                {menuItems.length > 0 ? <ul>{menuItems}</ul> : null}
            </div>
        );
    });

    const menuSections1 = menuSections.slice(0, menuSections.length / 2);
    const menuSections2 = menuSections.slice(menuSections.length / 2);

    return (
        <footer className='w-full mt-6'>
            <img
                className='w-full max-w-7xl mx-auto'
                src={
                    theme === 'dark' ? pikesPeakTraceDark : pikesPeakTraceLight
                }
                alt='Pikes Peak'
            />
            <div className='w-full bg-gray-200 dark:bg-gray-800'>
                <div className='max-w-7xl mx-auto px-4 py-8 flex flex-wrap'>
                    <div className='w-full pb-2 sm:w-1/3 sm:px-4 sm:flex sm:flex-col sm:justify-between text-center sm:text-left space-y-4'>
                        <div className='w-full'>
                            <div className='flex flex-wrap justify-around sm:justify-between items-center mb-2'>
                                <img
                                    className='block h-24 w-auto m-2'
                                    src={
                                        theme === 'dark'
                                            ? pprrLogoFullWhite
                                            : pprrLogoFullColor
                                    }
                                    alt='PPRR Logo'
                                />
                                <a
                                    href='https://www.rrca.org/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='block h-48 w-auto m-2'
                                >
                                    <img
                                        className='h-full'
                                        src={
                                            theme === 'dark'
                                                ? rrcaLogoYellow
                                                : rrcaLogoBlue
                                        }
                                        alt='RRCA Logo'
                                    />
                                </a>
                            </div>
                            <p className='font-sans italic text-xl font-semibold uppercase'>
                                The Largest Running Club in Colorado, Entirely
                                Volunteer Driven
                            </p>
                        </div>
                        <div className='w-full space-y-1'>
                            <div className='space-x-2'>
                                <a
                                    href='https://www.facebook.com/PPRRUN'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='inline-block w-12'
                                >
                                    <img
                                        className='h-full'
                                        src={
                                            theme === 'dark'
                                                ? facebookLogoLight
                                                : facebookLogoDark
                                        }
                                        alt='Facebook Logo'
                                    />
                                </a>
                                <a
                                    href='https://www.instagram.com/pp.rr.club/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='inline-block w-12'
                                >
                                    <img
                                        className='h-full'
                                        src={
                                            theme === 'dark'
                                                ? instagramLogoLight
                                                : instagramLogoDark
                                        }
                                        alt='Instagram Logo'
                                    />
                                </a>
                            </div>
                            <p>Est. 1978</p>
                            <p>PPRR is a 501(c)(3) nonprofit organization</p>
                            <p>
                                &copy; {currentYear} Pikes Peak Road Runners,
                                Inc.
                            </p>
                        </div>
                        <ThemeSwitcher />
                    </div>
                    <div className='w-full hidden sm:block sm:w-1/3 sm:px-4'>
                        {loading ? (
                            <div className='space-y-2'>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                            </div>
                        ) : error ? (
                            <h5>Unable to load sitemap</h5>
                        ) : (
                            <div className='space-y-2'>{menuSections1}</div>
                        )}
                    </div>
                    <div className='hidden sm:block sm:w-1/3 sm:px-4'>
                        {loading ? (
                            <div className='space-y-2'>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                                <div className='h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800'></div>
                            </div>
                        ) : (
                            <div className='space-y-2'>{menuSections2}</div>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(Footer);
