// Libraries
import ReactMarkdown from 'react-markdown';
import { BoltIcon } from '@heroicons/react/24/solid';
const gfm = require('remark-gfm');

const dataTableDetail = ({ data, columns }) => {
    const fieldOrder = {};
    columns.forEach((col) => {
        if (String(col.order) === 'null') {
            fieldOrder[col.selector] = 100;
        } else {
            fieldOrder[col.selector] = Number(col.order);
        }
    });

    const fields = Object.keys(data);
    const idIndex = fields.indexOf('id');
    if (idIndex > -1) {
        fields.splice(idIndex, 1);
    }

    fields.sort((a, b) => {
        if (b.toLowerCase().includes('name') || fieldOrder[a] > fieldOrder[b]) {
            return 1;
        }
        if (a.toLowerCase().includes('name') || fieldOrder[a] < fieldOrder[b]) {
            return -1;
        }
        return 0;
    });

    let additionalInfo = null;
    const additionalInfoIndex = fields.indexOf('additional_info');
    if (additionalInfoIndex > -1) {
        additionalInfo = (
            <ReactMarkdown
                children={data['additional_info']}
                remarkPlugins={[gfm]}
            />
        );
        fields.splice(additionalInfoIndex, 1);
    }

    const output = fields.map((field, idx) => {
        const bgClass =
            idx % 2 === 0
                ? 'bg-gray-50 dark:bg-gray-900'
                : 'bg-gray-100 dark:bg-gray-800';
        return (
            <tr key={field} className={`${bgClass}`}>
                <td className='font-bold font-serif border dark:border-gray-500 p-2'>
                    {field.toUpperCase()}
                </td>
                <td className='font-serif text-right border dark:border-gray-500 p-2'>
                    {data[field]}
                </td>
            </tr>
        );
    });

    return (
        <div className='p-4 w-full flex flex-wrap'>
            <div className='mr-4'>
                <table className='table-auto border-collapse border'>
                    <tbody>{output}</tbody>
                </table>
            </div>
            {additionalInfo ? (
                <div className='flex mt-4 md:mt-0'>
                    <BoltIcon
                        className='h-9 w-9 mr-2 text-red'
                        aria-hidden='true'
                    />
                    {additionalInfo}
                </div>
            ) : null}
        </div>
    );
};

export default dataTableDetail;
