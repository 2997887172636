// Libraries
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utils/general';

const ListBox = ({ selectedOption, onChange, options, widthClass }) => {
    return (
        <div
            className={classNames(
                'absolute sm:right-0',
                widthClass ? `${widthClass}` : 'w-auto'
            )}
        >
            <Listbox value={selectedOption} onChange={onChange}>
                <Listbox.Button className='relative w-full h-10 py-2 pl-3 pr-10 text-left bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md cursor-pointer focus:outline-none'>
                    <span className='block truncate font-sans'>
                        {selectedOption}
                    </span>
                    <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                        <ChevronUpDownIcon
                            className='w-5 h-5 heroicon-stroke-w-2.5 text-gray-500'
                            aria-hidden='true'
                        />
                    </span>
                </Listbox.Button>
                <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto bg-gray-50 dark:bg-gray-800 rounded-md shadow-lg max-h-60 focus:outline-none z-10'>
                    {options.map((year) => (
                        <Listbox.Option
                            key={year}
                            value={year}
                            className={({ active }) =>
                                `${active ? 'bg-gray-200 dark:bg-gray-700' : ''}
                                font-sans text-center cursor-pointer select-none relative py-2 pr-10 pl-3 min-h-[2.5rem]`
                            }
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`${
                                            selected
                                                ? 'font-bold'
                                                : 'font-normal'
                                        } block truncate`}
                                    >
                                        {year}
                                    </span>
                                    {selected ? (
                                        <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                                            <CheckIcon
                                                className='w-5 h-5 heroicon-stroke-w-2.5'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};

export default ListBox;
