let apiUrl;

switch (process.env.NODE_ENV) {
    case 'development':
        apiUrl = 'http://localhost:1337/api';
        break;
    case 'production':
        apiUrl = 'https://strapi.pprrun.org/api';
        break;
    default:
        apiUrl = 'https://strapi.pprrun.org/api';
        break;
}

export const apiBaseUrl = apiUrl;
