// Libraries
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import CarouselCard from './CarouselCard';

// Services, data, and media
import { classNames, isNullUndefined } from '../../utils/general';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

class MainCarousel extends Component {
    state = {
        index: 0,
        numCircles: 0,
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateCarousel);
        this.setState({ numCircles: this.getNumCircles() });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCarousel);
    }

    updateCarousel = () => {
        this.setState((prevState) => {
            let newIndex = prevState.index;
            let newNumCircles = this.getNumCircles();
            let diff = newNumCircles - prevState.numCircles;
            // Keep same index unless new number of circles is fewer
            if (diff <= 0) {
                newIndex = newIndex + diff >= 0 ? newIndex + diff : 0;
            }
            return {
                index: newIndex,
                numCircles: newNumCircles,
            };
        });
    };

    moveRight = () => {
        const { index, numCircles } = this.state;

        let newIndex;
        if (index >= numCircles - 1) {
            newIndex = 0;
        } else {
            newIndex = index + 1;
        }

        this.setState({
            index: newIndex,
        });
    };

    moveLeft = () => {
        const { index, numCircles } = this.state;

        let newIndex;
        if (index === 0) {
            newIndex = numCircles - 1;
        } else {
            newIndex = index - 1;
        }

        this.setState({
            index: newIndex,
        });
    };

    setIndex = (idx) => {
        this.setState({ index: idx });
    };

    getDiff = () => {
        // Calculate how much we move each slide (e.g. slide width)
        let diff;
        const screenWidthWithoutScrollbar =
            document.documentElement.clientWidth;
        /**
         * Max width is 1280px (7xl)
         * 1423px is the value above which 90% of the width is >= 1280px
         * Therefore we don't take 90% of the value
         * 0.9 * width = 1280 => width = 1423
         */
        if (screenWidthWithoutScrollbar >= 1423) {
            // 3 slides
            diff = 1280 / 3;
        } else if (window.innerWidth >= 1024) {
            // (lg) 3 slides 90% width of window / 3 = width * 0.9 * 1/3 = width * 0.3
            diff = screenWidthWithoutScrollbar * 0.3;
        } else if (window.innerWidth >= 640) {
            // (sm) 2 slides 90% width of window / 2 = width * 0.9 * 0.5
            diff = screenWidthWithoutScrollbar * 0.9 * 0.5;
        } else {
            // 1 slide 90% of window = width * 0.9
            diff = screenWidthWithoutScrollbar * 0.9;
        }
        return diff;
    };

    getNumCircles = () => {
        // Calculate number of navigation circles
        const { carouselData } = this.props;
        let numCircles;
        if (window.innerWidth >= 1024) {
            // (lg) 3 slides
            numCircles = carouselData.length - 2;
        } else if (window.innerWidth >= 640) {
            // (sm) 2 slides
            numCircles = carouselData.length - 1;
        } else {
            // 1 slide
            numCircles = carouselData.length;
        }
        return numCircles;
    };

    render() {
        const { index, numCircles } = this.state;
        const { carouselData } = this.props;

        if (isNullUndefined(carouselData) || carouselData.length === 0) {
            return null;
        }

        let circles = [];
        for (let i = 0; i < numCircles; i++) {
            let fillClass = 'bg-gray-300 dark:bg-gray-700';
            let hoverClass = 'hover:bg-gray-500';

            if (index === i) {
                fillClass = 'bg-gray-500';
                hoverClass = '';
            }

            circles.push(
                <div
                    key={i}
                    className={classNames(
                        fillClass,
                        hoverClass,
                        'inline-block rounded-full h-4 w-4 cursor-pointer'
                    )}
                    onClick={() => this.setIndex(i)}
                ></div>
            );
        }

        let diff = this.getDiff();
        let translateValue = index * -diff;

        let slideWidthsClasses = 'w-full sm:w-1/2 lg:w-1/3';
        let slidesContainerWidthClass = 'w-full';
        if (carouselData.length === 2) {
            slideWidthsClasses = 'w-full sm:w-1/2';
            slidesContainerWidthClass = 'w-full sm:w-3/4';
        }
        if (carouselData.length === 1) {
            slideWidthsClasses = 'w-full';
            slidesContainerWidthClass = 'w-full sm:w-3/5 lg:w-2/5';
        }

        const slides = carouselData.map((item, idx) => (
            <div
                key={item.id}
                className={classNames(
                    slideWidthsClasses,
                    'whitespace-normal inline-block px-2 sm:px-3 transition-transform duration-500 ease-in-out align-top'
                )}
                style={{
                    transform: `translateX(${translateValue}px)`,
                }}
            >
                <CarouselCard cardData={item} />
            </div>
        ));

        return (
            <div className='w-full overflow-hidden'>
                <div className='w-[90%] max-w-7xl mx-auto'>
                    <div
                        className={classNames(
                            slidesContainerWidthClass,
                            'mx-auto mb-4 whitespace-nowrap overflow-visible'
                        )}
                    >
                        {slides}
                    </div>
                    {circles.length >= 2 ? (
                        <div className='w-full relative h-10 mx-auto mb-4'>
                            <div
                                className={classNames(
                                    index <= 0 ? 'hidden' : '',
                                    'w-12 cursor-pointer absolute left-0 sm:left-[15%] md:left-[20%] lg:left-[25%] xl:left-[33%] z-20'
                                )}
                                onClick={this.moveLeft}
                            >
                                <ChevronLeftIcon
                                    className='h-12 w-12 heroicon-stroke-w-2 text-gray-500'
                                    aria-hidden='true'
                                />
                            </div>
                            <div className='absolute w-full flex justify-center items-center space-x-2 pt-[14px] z-10'>
                                {circles}
                            </div>
                            <div
                                className={classNames(
                                    index >= numCircles - 1 ? 'hidden' : '',
                                    'w-12 cursor-pointer absolute right-0 sm:right-[15%] md:right-[20%] lg:right-[25%] xl:right-[33%] z-20'
                                )}
                                onClick={this.moveRight}
                            >
                                <ChevronRightIcon
                                    className='h-12 w-12 heroicon-stroke-w-2 text-gray-500'
                                    aria-hidden='true'
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withRouter(MainCarousel);
