import { Link } from 'react-router-dom';
import { classNames } from '../../utils/general';

const linkButton = ({ link }) => {
    const classes = classNames(
        link.color === 'gray' ? 'bg-gray-700' : `bg-${link.color}`,
        link.color === 'yellow' ? 'text-gray-900' : 'text-gray-50',
        'm-1 px-3 py-3 rounded-md text-base font-serif font-semibold no-underline'
    );

    if (link.external) {
        return (
            <a
                key={link.link_text}
                href={link.url}
                target='_blank'
                rel='noopener noreferrer'
                className={classes}
            >
                {link.link_text.toUpperCase()}
            </a>
        );
    }
    return (
        <Link key={link.link_text} to={link.url} className={classes}>
            {link.link_text.toUpperCase()}
        </Link>
    );
};

export default linkButton;
