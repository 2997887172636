// Libraries
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

// Components
import MediaCarousel from '../../components/MediaCarousel/MediaCarousel';
import LinkButton from '../../components/LinkButton/LinkButton';
import HistoricalResultsDisplay from './HistoricalResultsDisplay';
import EventInformation from './EventInformation';
import SponsorsCarousel from '../../components/SponsorsCarousel/SponsorsCarousel';
import ResponsiveMedia from '../../components/ResponsiveMedia/ResponsiveMedia';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { isNullUndefined } from '../../utils/general';
import { getRacePage } from '../../services/racePageService';
import {
    getYear,
    getMonthName,
    availableYears,
    currentYear,
    currentMonth,
    monthNames,
    getDateDifference,
} from '../../utils/datesAndTimes';
import { groupResultsByMonthOrYear } from '../../utils/results';
const gfm = require('remark-gfm');

// Set up yearDisclosures object
const initialYearDisclosures = {};
availableYears.forEach((year) => {
    if (!initialYearDisclosures.hasOwnProperty(year)) {
        initialYearDisclosures[year] = false;
    }
});

class RacePage extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        pageData: null,
        resultsGrouped: null,
        events: [],
        yearDisclosures: initialYearDisclosures,
    };

    componentDidMount() {
        this.getPageData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState(
                { loading: true, yearDisclosures: initialYearDisclosures },
                this.getPageData
            );
            window.scrollTo(0, 0);
        }
    }

    getPageData() {
        let slug = this.props.location?.pathname;
        if (!isNullUndefined(slug)) {
            // Race pages slug format is /races/<race-name> => slug is <race-name>
            slug = slug.split('/').pop();
            if (!isNullUndefined(slug)) {
                getRacePage(slug, currentYear)
                    .then((response) => {
                        this.setState(
                            {
                                loading: false,
                                pageData: response.data.data,
                            },
                            () => {
                                this.filterEvents();
                                this.groupResults();
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            error: true,
                            errorMsg: error.response.statusText,
                        });
                    });
            }
        } else {
            this.setState({ pageData: null, errorMsg: 'Page not found' });
        }
    }

    filterEvents() {
        const { pageData } = this.state;
        const events = pageData.attributes.events?.data;

        if (events) {
            const { race_month, acronym } = pageData.attributes;

            // Determine appropriate year
            const raceMonthInt = race_month
                ? monthNames.indexOf(race_month)
                : 0;
            let filterEventsYear = currentYear;
            // Note: Nielson and Community Cup fetch current year until December
            if (acronym === 'NC' || acronym === 'TCC') {
                const nowDateObj = new Date();
                const compareDate =
                    acronym === 'NC'
                        ? `${currentYear}-12-15`
                        : `${currentYear}-12-01`;
                const compareDateObj = new Date(compareDate);
                if (nowDateObj > compareDateObj) {
                    filterEventsYear = currentYear + 1;
                }
            } else if (currentMonth >= raceMonthInt + 3) {
                filterEventsYear = currentYear + 1;
            }

            // Filter events based on appropriate year
            let filteredEvents = events.filter((event) => {
                return (
                    new Date(event.attributes.date_time).getFullYear() ===
                    filterEventsYear
                );
            });

            if (acronym === 'NC' || acronym === 'TCC') {
                // Only show upcoming race for Nielson and Community Cup
                filteredEvents = filteredEvents.filter((event) => {
                    const dateObj = new Date(event.attributes.date_time);
                    const todayDateObj = new Date();
                    const diffDays = getDateDifference(dateObj, todayDateObj);
                    return diffDays >= 0 && diffDays < 36;
                });
                // Take first (soonest) event if there are still two
                if (filteredEvents.length > 1) {
                    filteredEvents = [filteredEvents[0]];
                }
                if (filteredEvents.length > 0) {
                    filteredEvents[0]['attributes']['is_next_event'] = true;
                }
            } else {
                // Show all events for other races
                const todayDateObj = new Date();
                for (let i = 0; i < filteredEvents.length; i++) {
                    const dateObj = new Date(
                        filteredEvents[i].attributes.date_time
                    );
                    if (todayDateObj <= dateObj) {
                        filteredEvents[i]['attributes']['is_next_event'] = true;
                        break;
                    }
                }
            }

            this.setState({ events: filteredEvents });
        } else {
            this.setState({ events: [] });
        }
    }

    groupResults() {
        const { pageData } = this.state;
        const results = pageData.attributes.results?.data;

        const resultsGrouped = groupResultsByMonthOrYear(results, false);

        if (!isNullUndefined(resultsGrouped)) {
            this.setState({ resultsGrouped }, () => {
                const year = resultsGrouped.keys().next().value;
                if (!this.state.yearDisclosures[year]) {
                    this.toggleDisclosure(year);
                }
            });
        } else {
            this.setState({ resultsGrouped: null });
        }
    }

    toggleDisclosure = (year) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                yearDisclosures: {
                    ...prevState.yearDisclosures,
                    [year]: !prevState.yearDisclosures[year],
                },
            };
        });
    };

    render() {
        const {
            pageData,
            resultsGrouped,
            yearDisclosures,
            events,
            loading,
            error,
            errorMsg,
        } = this.state;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }

        if (error || isNullUndefined(pageData)) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>{errorMsg}</h3>
                </div>
            );
        }

        if (pageData) {
            const {
                information,
                show_results,
                show_native_entry_list_link,
                link,
                name,
                tagline,
                updatedAt,
                acronym,
            } = pageData.attributes;
            const cover_media = pageData.attributes.cover_media.data;
            const entryLists = pageData.attributes.entry_lists?.data;

            const tableOfContents = information.map((block) => {
                const { id, title } = block;
                return (
                    <a
                        key={id}
                        href={'#' + title.replace(/\s+/g, '')}
                        className='font-semibold font-serif text-lg underline my-1 xl:mx-2 xl:first-of-type:ml-0'
                    >
                        {title.toUpperCase()}
                    </a>
                );
            });

            if (show_results) {
                tableOfContents.push(
                    <a
                        key='results'
                        href={'#results'}
                        className='font-semibold font-serif text-lg underline my-1 xl:ml-2'
                    >
                        RESULTS
                    </a>
                );
            }

            const eventInfo = events.map((event) => {
                return (
                    <EventInformation
                        key={event.id}
                        event={event.attributes}
                        acronym={acronym}
                    />
                );
            });

            const links = link.map((link) => (
                <LinkButton link={link} key={link.id} />
            ));

            let entryListLinkButton = null;
            if (
                !isNullUndefined(entryLists) &&
                entryLists.length > 0 &&
                show_native_entry_list_link
            ) {
                // Take the first entry list, the most recent is first
                const entryList = entryLists[0];
                const { race_date, entry_list_id } = entryList.attributes;
                const entryListYear = getYear(race_date);
                let linkText = `${entryListYear} Entry List`;
                if (entry_list_id.includes('NC')) {
                    const entryListMonth = getMonthName(race_date);
                    linkText = `${entryListMonth} Entry List`;
                }
                const entryListLink = {
                    color: 'green',
                    external: false,
                    link_text: linkText,
                    url: `/entry-lists/${entry_list_id}`,
                };
                entryListLinkButton = <LinkButton link={entryListLink} />;
            }

            const informationBlocks = information.map((block) => {
                const { id, title, content } = block;
                const media = block.media.data;
                return (
                    <div key={id}>
                        <h2 className='relative'>
                            <span
                                id={title.replace(/\s+/g, '')}
                                className='absolute -top-16'
                            ></span>
                            {title}
                        </h2>
                        <ReactMarkdown
                            children={content}
                            remarkPlugins={[gfm]}
                            linkTarget={(href, children, title) =>
                                href.includes('http') ? '_blank' : null
                            }
                        />
                        {media && media.length > 0 ? (
                            <MediaCarousel media={media} />
                        ) : null}
                    </div>
                );
            });

            return (
                <>
                    <div className='w-full mx-auto p-4 prose dark:prose-invert prose-img:rounded-xl lg:max-w-4xl'>
                        <h1>{name}</h1>
                        <h3 className='font-serif italic'>{tagline}</h3>
                        {cover_media ? (
                            <figure className='w-full'>
                                <ResponsiveMedia
                                    media={cover_media}
                                    classes='w-full'
                                />
                                <figcaption>
                                    {cover_media.attributes.caption}
                                </figcaption>
                            </figure>
                        ) : null}
                        <p>
                            <strong>Last Updated:&nbsp;</strong>
                            {new Date(updatedAt).toLocaleString()}
                        </p>
                        <div className='not-prose flex flex-col xl:flex-row xl:flex-wrap xl:justify-evenly'>
                            {tableOfContents}
                        </div>

                        {eventInfo.length > 0 ? (
                            <div className='not-prose w-full my-4 grid gap-4 grid-cols-1 sm:grid-cols-2 sm:gap-6'>
                                {eventInfo}
                            </div>
                        ) : (
                            <h2 className='italic'>
                                Event date(s) and location(s) not yet available
                            </h2>
                        )}

                        {links.length > 0 || entryListLinkButton ? (
                            <div className='flex flex-wrap -ml-1 -mt-1'>
                                {entryListLinkButton}
                                {links}
                            </div>
                        ) : null}
                    </div>
                    <SponsorsCarousel />
                    <div className='w-full mx-auto p-4 prose dark:prose-invert prose-img:rounded-xl lg:max-w-4xl'>
                        {informationBlocks}
                        {show_results ? (
                            <>
                                <h2 className='relative'>
                                    <span
                                        id='results'
                                        className='absolute -top-16'
                                    ></span>
                                    Results
                                </h2>
                                <HistoricalResultsDisplay
                                    resultsGrouped={resultsGrouped}
                                    yearDisclosures={yearDisclosures}
                                    toggleDisclosure={this.toggleDisclosure}
                                />
                            </>
                        ) : null}
                    </div>
                </>
            );
        }
    }
}

export default RacePage;
