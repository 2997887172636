// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Menu } from '@headlessui/react';

// Services, data, and media
import { classNames } from '../../../utils/general';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const desktopMenuItems = ({ loading, menuItems }) => {
    if (loading) {
        return (
            <>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 rounded-md bg-gray-100 dark:bg-gray-800'></div>
            </>
        );
    }
    return menuItems.map((item) => {
        if (!item || !item?.attributes) {
            return null;
        }
        const { name, sub_menu_items, external, slug } = item.attributes;
        if (item.attributes?.sub_menu_items?.data?.length > 0) {
            return (
                <Menu key={item.id} as='div' className='relative'>
                    {({ open }) => (
                        <>
                            <Menu.Button
                                className={classNames(
                                    open ? 'bg-gray-200 dark:bg-gray-800' : '',
                                    'menu-button flex items-center justify-center focus:outline-none'
                                )}
                            >
                                <span className='sr-only'>{name}</span>
                                {name.toUpperCase()}
                                {open ? (
                                    <ChevronUpIcon
                                        className='ml-1 h-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <ChevronDownIcon
                                        className='ml-1 h-4 w-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                )}
                            </Menu.Button>
                            <Menu.Items className='origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-100 dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                {sub_menu_items.data.map((subMenuItem) => {
                                    const { external, slug, name } =
                                        subMenuItem.attributes;
                                    return (
                                        <Menu.Item key={subMenuItem.id}>
                                            {({ active }) => {
                                                if (external) {
                                                    return (
                                                        <a
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-200 dark:bg-gray-700'
                                                                    : '',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                            href={slug}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            {name}
                                                        </a>
                                                    );
                                                }
                                                return (
                                                    <Link
                                                        to={slug}
                                                        className={classNames(
                                                            active
                                                                ? 'bg-gray-200 dark:bg-gray-700'
                                                                : '',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        {name}
                                                    </Link>
                                                );
                                            }}
                                        </Menu.Item>
                                    );
                                })}
                            </Menu.Items>
                        </>
                    )}
                </Menu>
            );
        }
        return (
            <div key={item.id}>
                {external ? (
                    <a
                        className='menu-button'
                        href={slug}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {name}
                    </a>
                ) : (
                    <Link to={slug} className='menu-button'>
                        {name.toUpperCase()}
                    </Link>
                )}
            </div>
        );
    });
};

export default desktopMenuItems;
