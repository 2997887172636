import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { isNullUndefined } from '../../utils/general';

const heroMedia = ({ homePageData }) => {
    if (isNullUndefined(homePageData) || !homePageData.attributes) {
        return null;
    }
    const { hero_media, hero_media_large } = homePageData.attributes;
    let heroMediaUrl = null;
    if (!isNullUndefined(hero_media) && !isNullUndefined(hero_media_large)) {
        heroMediaUrl = hero_media.data?.attributes?.url;
        if (window.innerWidth >= 1200) {
            heroMediaUrl = hero_media_large.data?.attributes?.url;
        }
    }

    return (
        <div
            className='w-full h-[calc(100vh-4rem)] relative mb-4 mx-auto bg-no-repeat bg-cover bg-[40%_10%]'
            style={{
                backgroundImage: heroMediaUrl ? `url(${heroMediaUrl})` : null,
            }}
        >
            <div className='absolute w-full h-full bg-black opacity-25'></div>
            <div className='absolute w-full lg:w-1/2 xl:w-[45%] 2xl:w-1/3 min-[2100px]:w-1/4 p-4 lg:p-8 text-gray-50 drop-shadow-[0_4px_4px_black] text-right bottom-20 lg:top-1/2 lg:-translate-y-1/2 lg:left-1/2 xl:left-[55%] 2xl:left-2/3 min-[1800px]:left-[60%]'>
                <h2 className='font-sans font-bold text-5xl uppercase sm:text-6xl lg:text-7xl xl:text-8xl lg:mb-4'>
                    Runners
                    <br /> with <br />
                    Altitude
                </h2>
                <p className='font-serif font-semibold text-3xl hidden lg:block'>
                    We are the largest running club in Colorado, entirely
                    volunteer driven!
                </p>
            </div>
            <div
                className='absolute bottom-0 left-1/2 -translate-x-1/2 w-16 cursor-pointer'
                onClick={() => {
                    window.scrollTo({
                        top: window.innerHeight - 64,
                        left: 0,
                        behavior: 'smooth',
                    });
                }}
            >
                <ChevronDownIcon
                    className='h-16 w-16 heroicon-stroke-w-2 text-gray-50 animate-bounce'
                    aria-hidden='true'
                />
            </div>
        </div>
    );
};

export default heroMedia;
