// Libraries
import React, { Component } from 'react';

// Components
import HeroMedia from './HeroMedia';
import MainCarousel from './MainCarousel';
import SponsorsCarousel from '../../components/SponsorsCarousel/SponsorsCarousel';
import HomePageInformation from './HomePageInformation';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { getISODate } from '../../utils/datesAndTimes';
import {
    getHomePageData,
    getHomePageCarouselItems,
} from '../../services/homePageService';

class HomePage extends Component {
    state = {
        loading: true,
        error: false,
        homePageData: {},
        carouselData: [],
        adsData: [],
    };

    componentDidMount() {
        const date = getISODate();
        Promise.all([getHomePageData(), getHomePageCarouselItems(date)])
            .then((responses) => {
                this.setState({
                    loading: false,
                    homePageData: responses[0].data?.data,
                    carouselData: responses[1].data?.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
        window.scrollTo(0, 0);
    }

    render() {
        const { homePageData, carouselData, loading, error } = this.state;

        if (loading) {
            return (
                <LoadingSpinner classes='w-full h-96 flex justify-center items-center' />
            );
        }

        if (error) {
            return (
                <div className='w-full h-96 flex justify-center items-center'>
                    <h3 className='text-red'>An error has occurred</h3>
                </div>
            );
        }

        const information = homePageData?.attributes?.information;

        return (
            <div className='flex flex-row flex-wrap justify-items-center mx-auto'>
                <HeroMedia homePageData={homePageData} />
                <MainCarousel carouselData={carouselData} />
                <SponsorsCarousel />
                <HomePageInformation info={information} />
            </div>
        );
    }
}

export default HomePage;
