// Libraries
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';

const loadingSpinner = ({ theme, classes }) => (
    <Loader
        className={classes}
        type='Oval'
        color={theme === 'dark' ? '#F9FAFB' : '#213064'}
    />
);

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(loadingSpinner);
