// Libraries
import React, { Component } from 'react';

// Components
import MobileNavbar from './MobileNavbar/MobileNavbar';
import DesktopNavbar from './DesktopNavbar/DesktopNavbar';

// Services, data, and media
import { getMenuItems, getCTAButtons } from '../../services/navigationService';

class Navbar extends Component {
    state = {
        loading: true,
        error: false,
        menuItemsData: [],
        ctaButtonsData: [],
    };

    componentDidMount() {
        Promise.all([getMenuItems(), getCTAButtons()])
            .then((responses) => {
                this.setState({
                    loading: false,
                    menuItemsData: responses[0].data?.data,
                    ctaButtonsData: responses[1].data?.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    render() {
        const { menuItemsData, ctaButtonsData, loading } = this.state;

        return (
            <>
                <div className='xl:hidden'>
                    <MobileNavbar
                        loading={loading}
                        menuItems={menuItemsData}
                        ctaButtons={ctaButtonsData}
                    />
                </div>
                <div className='hidden xl:block'>
                    <DesktopNavbar
                        loading={loading}
                        menuItems={menuItemsData}
                        ctaButtons={ctaButtonsData}
                    />
                </div>
            </>
        );
    }
}

export default Navbar;
