import { Link } from 'react-router-dom';
import EventTypeIcons from './EventTypeIcons';
import { prettyDateTimeForCalendar } from '../../utils/datesAndTimes';

const calendarColumns = [
    {
        id: 'date_time',
        name: 'DATE & TIME',
        selector: (row) => {
            const dateObj = new Date(row.attributes.date_time);
            return prettyDateTimeForCalendar(dateObj);
        },
        wrap: true,
    },
    {
        id: 'event_name',
        name: 'EVENT',
        selector: (row) => {
            if (
                row.attributes.event_name_link &&
                row.attributes.event_name_link !== ''
            ) {
                if (row.attributes.event_name_link.includes('http')) {
                    // External
                    return (
                        <div className='flex items-center'>
                            <a
                                className='hover:underline'
                                href={row.attributes.event_name_link}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {row.attributes.event_name}
                            </a>
                            <EventTypeIcons
                                clubEvent={row.attributes.club_event}
                                clubAssistedEvent={
                                    row.attributes.club_assisted_event
                                }
                                communityCupEvent={
                                    row.attributes.community_cup_event
                                }
                            />
                        </div>
                    );
                } else {
                    // Internal
                    return (
                        <div className='flex items-center'>
                            <Link
                                className='hover:underline'
                                to={row.attributes.event_name_link}
                            >
                                {row.attributes.event_name}
                            </Link>
                            <EventTypeIcons
                                clubEvent={row.attributes.club_event}
                                clubAssistedEvent={
                                    row.attributes.club_assisted_event
                                }
                                communityCupEvent={
                                    row.attributes.community_cup_event
                                }
                            />
                        </div>
                    );
                }
            }
            return (
                <div className='flex items-center'>
                    <span>{row.attributes.event_name}</span>
                    <EventTypeIcons
                        clubEvent={row.attributes.club_event}
                        clubAssistedEvent={row.attributes.club_assisted_event}
                        communityCupEvent={row.attributes.community_cup_event}
                    />
                </div>
            );
        },
        wrap: true,
        grow: 1.5,
    },
    {
        id: 'distance',
        name: 'DISTANCE / TIME',
        selector: (row) => row.attributes.distance,
        wrap: true,
        hide: 640,
    },
    {
        id: 'location',
        name: 'LOCATION',
        selector: (row) => {
            if (
                row.attributes.location_link &&
                row.attributes.location_link !== ''
            ) {
                if (row.attributes.location_link.includes('http')) {
                    // External
                    return (
                        <a
                            className='hover:underline'
                            href={row.attributes.location_link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {row.attributes.location}
                        </a>
                    );
                } else {
                    // Internal
                    return (
                        <Link
                            to={row.attributes.location_link}
                            className='hover:underline'
                        >
                            {row.attributes.location}
                        </Link>
                    );
                }
            }
            return row.attributes.location;
        },
        wrap: true,
        hide: 896,
        grow: 1.5,
    },
    {
        id: 'link',
        name: 'LINKS',
        selector: (row) => {
            const linksArr = row.attributes.link.map((link) => {
                if (link.external) {
                    // External
                    return (
                        <li key={link.id} className='font-sans hover:underline'>
                            <a
                                href={link.url}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {link.link_text}
                            </a>
                        </li>
                    );
                } else {
                    // Internal
                    return (
                        <li key={link.id} className='font-sans hover:underline'>
                            <Link to={link.url}>{link.link_text}</Link>
                        </li>
                    );
                }
            });
            return <ul>{linksArr}</ul>;
        },
        wrap: true,
        hide: 1024,
    },
];

export default calendarColumns;
