import { createStore, combineReducers } from 'redux';

import stateReducer from '../reducers/state';

const store = createStore(
    combineReducers({
        state: stateReducer,
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
