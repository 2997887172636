// Services, data, and media
import { prettyDateTime, getDateDifference } from '../../utils/datesAndTimes';
import {
    MapPinIcon,
    FlagIcon,
    CalendarIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../../utils/general';

const eventInformation = ({ event, acronym }) => {
    let dateObj,
        dateTimeString = null;
    let isEventUpcoming = false;
    let isNextEvent = false;
    let showUpcomingBorder = false;

    if (event.is_next_event) {
        isNextEvent = true;
    }

    if (event.date_time) {
        dateObj = new Date(event.date_time);
        const todayDateObj = new Date();
        const diffDays = getDateDifference(dateObj, todayDateObj);
        if (diffDays >= 0 && diffDays < 14) {
            isEventUpcoming = true;
        }
        dateTimeString = prettyDateTime(dateObj);
    }

    if (isNextEvent && isEventUpcoming) {
        // Only show border for next event if it's within 14 days
        showUpcomingBorder = true;
    }

    return (
        <div
            className={classNames(
                'p-4 rounded-lg bg-gray-100 dark:bg-gray-800 shadow-xl',
                showUpcomingBorder || event.cancelled ? 'border-4' : '',
                event.cancelled
                    ? 'border-red'
                    : showUpcomingBorder
                    ? 'border-yellow'
                    : ''
            )}
        >
            <h4
                className={classNames(
                    'font-serif font-bold mb-2',
                    event.cancelled ? 'text-red' : ''
                )}
            >
                {event.event_name.toUpperCase()}
            </h4>
            {event.cancelled ? (
                <h4 className='font-serif font-bold mb-2 text-red'>
                    CANCELLED
                </h4>
            ) : null}
            {event.distance ? (
                <div className='flex items-center my-1'>
                    <div className='w-6 mr-2'>
                        <FlagIcon
                            className='h-6 w-6 heroicon-stroke-w-2'
                            aria-hidden='true'
                        />
                    </div>
                    <span>{event.distance}</span>
                </div>
            ) : null}
            {dateObj !== null ? (
                <div className='flex items-center my-1'>
                    <div className='w-6 mr-2'>
                        <CalendarIcon
                            className='h-6 w-6 heroicon-stroke-w-2'
                            aria-hidden='true'
                        />
                    </div>
                    <span>{dateTimeString}</span>
                </div>
            ) : null}
            {event.location ? (
                <div className='flex items-center my-1'>
                    <div className='w-6 mr-2'>
                        <MapPinIcon
                            className='h-6 w-6 heroicon-stroke-w-2'
                            aria-hidden='true'
                        />
                    </div>
                    {event.location_link ? (
                        <a
                            className='hover:underline'
                            href={event.location_link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {event.location}
                        </a>
                    ) : (
                        <span>{event.location}</span>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default eventInformation;
