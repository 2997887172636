const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');

const getPage = (slug) => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/pages/${slug}`,
    });
};

module.exports = {
    getPage,
};
