const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getEntryLists = (startDate, endDate) => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/entry-lists?date_gte=${startDate}&date_lte=${endDate}`,
    });
};

const getEntryListsForCalendarEvent = (eventId) => {
    const query = qs.stringify({
        filters: {
            event: {
                id: {
                    $eq: eventId,
                },
            },
        },
        fields: ['id', 'entry_list_id'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/entry-lists?${query}`,
    });
};

const getEntryList = (entryListId) => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/entry-lists/${entryListId}`,
    });
};

module.exports = {
    getEntryLists,
    getEntryListsForCalendarEvent,
    getEntryList,
};
