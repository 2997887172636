// Libraries
import { Link } from 'react-router-dom';

// Components
import SponsorsCarousel from '../../components/SponsorsCarousel/SponsorsCarousel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { isNullUndefined } from '../../utils/general';

const courseRecordsDisplay = (props) => {
    const { loading, error, courseRecords, selectedOption, isPersonal } = props;

    if (loading) {
        return <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />;
    }

    if (error) {
        return (
            <div className='flex justify-center pt-12 lg:pt-48'>
                <h3>An error has occurred!</h3>
            </div>
        );
    }

    if (!isNullUndefined(courseRecords) && courseRecords.length > 0) {
        const courseRecordLinks = courseRecords.map((record, idx) => {
            const { course_id, course_link_name, is_active } =
                record.attributes;
            return (
                <li key={record.id} className='hover:underline'>
                    <Link
                        to={{
                            pathname: isPersonal
                                ? `/personal-course-records/${course_id}`
                                : `/course-records/${course_id}`,
                            state: {
                                showCourseRecordsNavigation: true,
                            },
                        }}
                    >
                        {course_link_name}{' '}
                        {!is_active ? <span>({course_id})</span> : null}
                    </Link>
                </li>
            );
        });

        // Split links into 2 arrays
        const courseRecordLinks1 = courseRecordLinks.slice(
            0,
            courseRecordLinks.length / 2
        );
        const courseRecordLinks2 = courseRecordLinks.slice(
            courseRecordLinks.length / 2
        );
        return (
            <>
                <div className='max-w-3xl mx-auto w-full p-4 pt-2'>
                    <div className='flex flex-wrap'>
                        <div className='w-full md:w-1/2'>
                            <ul>{courseRecordLinks1}</ul>
                        </div>
                        <div className='w-full md:w-1/2'>
                            <ul className='md:pl-4'>{courseRecordLinks2}</ul>
                        </div>
                    </div>
                </div>
                <SponsorsCarousel />
            </>
        );
    }

    const noRecordsText =
        selectedOption === 'Active Courses' ? 'active' : 'inactive';

    return (
        <>
            <div className='max-w-3xl w-full mx-auto py-4 text-center'>
                <h3>No course records for {noRecordsText} courses</h3>
            </div>
            <SponsorsCarousel />
        </>
    );
};

export default courseRecordsDisplay;
