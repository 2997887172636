// Libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Page from '../../scenes/Page/Page';
import RacePage from '../../scenes/RacePage/RacePage';
import HomePage from '../../scenes/HomePage/HomePage';
import CalendarPage from '../../scenes/CalendarPage/CalendarPage';
import EventFormPage from '../../scenes/EventFormPage/EventFormPage';
import ResultsPage from '../../scenes/ResultsPage/ResultsPage';
import ResultPage from '../../scenes/ResultPage/ResultPage';
import CourseRecordsPage from '../../scenes/CourseRecordsPage/CourseRecordsPage';
import CourseRecordPage from '../../scenes/CourseRecordPage/CourseRecordPage';
import RunningGroupsPage from '../../scenes/RunningGroupsPage/RunningGroupsPage';
import RunningGroupsFormPage from '../../scenes/RunningGroupsFormPage/RunningGroupsFormPage';
import EntryListPage from '../../scenes/EntryListPage/EntryListPage';

const layout = () => (
    <div className='w-full'>
        <Navbar />
        <main className='content-area mt-16'>
            <Switch>
                <Route path='/' exact component={HomePage} />
                <Route path='/calendar' exact component={CalendarPage} />
                <Route path='/calendar/add' exact component={EventFormPage} />
                <Route path='/races/:page' component={RacePage} />
                <Route path='/results' exact component={ResultsPage} />
                <Route path='/results/:id' component={ResultPage} />
                <Route
                    path='/course-records'
                    exact
                    render={(props) => (
                        <CourseRecordsPage {...props} isPersonal={false} />
                    )}
                />
                <Route
                    path='/course-records/:id'
                    render={(props) => (
                        <CourseRecordPage {...props} isPersonal={false} />
                    )}
                />
                <Route
                    path='/personal-course-records'
                    exact
                    render={(props) => (
                        <CourseRecordsPage {...props} isPersonal={true} />
                    )}
                />
                <Route
                    path='/personal-course-records/:id'
                    render={(props) => (
                        <CourseRecordPage {...props} isPersonal={true} />
                    )}
                />
                <Route path='/entry-lists/:id' component={EntryListPage} />
                <Route
                    path='/running-groups'
                    exact
                    component={RunningGroupsPage}
                />
                <Route
                    path='/running-groups/add'
                    exact
                    component={RunningGroupsFormPage}
                />
                <Route path='/:page' component={Page} />
            </Switch>
        </main>
        <Footer />
    </div>
);

export default layout;
