const calendarConditionalRowStyles = [
    {
        when: (row) => row.attributes.cancelled,
        style: {
            color: '#C02033',
            textDecoration: 'line-through solid #C02033',
        },
    },
];

module.exports = {
    calendarConditionalRowStyles,
};
