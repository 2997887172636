// Libraries
import { useRef } from 'react';

// Services, data, and media
import { getMediaUrl } from '../../utils/general';
import useElementSize from '../../utils/useElementSize';

const ResponsiveMedia = ({ media, classes = '', backgroundImage = false }) => {
    const mediaRef = useRef();
    const { elementWidth, elementHeight } = useElementSize(mediaRef);

    if (!media.attributes) {
        return null;
    }

    const { alternativeText } = media.attributes;

    if (backgroundImage) {
        return (
            <div
                ref={mediaRef}
                className={classes}
                style={{
                    backgroundImage: `url(${getMediaUrl(
                        media,
                        elementWidth,
                        elementHeight
                    )})`,
                }}
            ></div>
        );
    }
    return (
        <img
            ref={mediaRef}
            className={classes}
            src={getMediaUrl(media, elementWidth, elementHeight)}
            alt={alternativeText}
        />
    );
};

export default ResponsiveMedia;
