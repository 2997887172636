// Components
import LinkButton from '../../components/LinkButton/LinkButton';
import ResponsiveMedia from '../../components/ResponsiveMedia/ResponsiveMedia';

// Services, data, and media
import { classNames, isNullUndefined } from '../../utils/general';

const HomePageInformation = ({ info }) => {
    if (!isNullUndefined(info) && info.length > 0) {
        const infoBlocks = info.map((item, idx) => {
            const { id, links, title, body } = item;
            const media = item.media?.data;

            const linkButtons = links.map((link) => (
                <LinkButton link={link} key={link.id} />
            ));
            const contentOrderClass = idx % 2 === 0 ? 'order-1' : 'order-2';
            const mediaOrderClass = idx % 2 === 0 ? 'order-2' : 'order-1';

            return (
                <div
                    key={id}
                    className='w-full max-w-7xl mx-auto flex flex-wrap items-center'
                >
                    <div
                        className={classNames(
                            contentOrderClass,
                            'w-full sm:w-1/2 p-4 md:p-6 lg:p-8 xl:p-12 prose dark:prose-invert'
                        )}
                    >
                        <h2 className='font-serif font-bold text-3xl mt-0'>
                            {title}
                        </h2>
                        {media ? (
                            <ResponsiveMedia
                                media={media}
                                classes='w-full rounded-xl shadow-xl sm:hidden'
                            />
                        ) : null}
                        <p>{body}</p>
                        {linkButtons.length > 0 ? (
                            <div className='flex flex-wrap items-start -ml-1 -mt-1 mb-3'>
                                {linkButtons}
                            </div>
                        ) : null}
                    </div>
                    {media ? (
                        <div
                            className={classNames(
                                mediaOrderClass,
                                'w-1/2 p-4 md:p-6 lg:p-8 xl:p-12 hidden sm:block'
                            )}
                        >
                            <ResponsiveMedia
                                media={media}
                                classes='w-full rounded-xl shadow-xl'
                            />
                        </div>
                    ) : null}
                </div>
            );
        });
        return <div className='w-full my-4 space-y-4'>{infoBlocks}</div>;
    }
    return null;
};

export default HomePageInformation;
