// Libraries
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

// Components
import Filter from './Filter';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { customStyles } from '../../utils/dataTablesStyles';
import { debounce } from '../../utils/general';

class DataTableFilter extends Component {
    state = {
        filterText: '',
        filteredItems: this.props.section.data,
        filterColumns: {},
        loading: false,
    };

    componentDidMount() {
        // Set up filter columns (e.g. age group, sex)
        // Filter columns will have filter: true in the columns JSON
        const rawFilterColumns = this.props.section.columns.filter((col) => {
            return col.filter;
        });

        const filterColumns = [];
        rawFilterColumns.forEach((col) => {
            const allValues = [];
            this.props.section.data.forEach((item) => {
                allValues.push(item[col.selector]);
            });
            const uniqueValues = allValues.filter((item, index, array) => {
                return array.indexOf(item) === index;
            });
            uniqueValues.sort();
            // Add empty option to options array if it's not there already
            if (!uniqueValues.includes('')) {
                uniqueValues.unshift('');
            }
            const colObj = {
                name: col.name,
                selector: col.selector,
                setValue: this.setColumnFilterValue,
                value: '',
                options: uniqueValues,
            };
            filterColumns[col.selector] = colObj;
        });
        this.setState({ filterColumns });
    }

    setColumnFilterValue = (value, colFilter) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                filterColumns: {
                    ...prevState.filterColumns,
                    [colFilter]: {
                        ...prevState.filterColumns[colFilter],
                        value: value,
                    },
                },
                loading: true,
            };
        }, this.filterItems);
    };

    debouncedFilterItems = debounce(() => this.filterItems(), 500);

    setFilterText = (filterText) => {
        this.setState({ filterText, loading: true }, () =>
            this.debouncedFilterItems()
        );
    };

    handleClear = () => {
        this.setState(
            {
                filterText: '',
                loading: false,
            },
            this.filterItems
        );
    };

    filterItems = () => {
        // The filter combines the search input and any filter columns
        const { filterColumns, filterText } = this.state;
        let filteredItems = this.props.section.data;

        // Filter columns (e.g. age group, sex)
        Object.keys(filterColumns).forEach((col) => {
            const value = filterColumns[col].value;
            if (value !== '') {
                // Empty string value does not apply any filter
                filteredItems = filteredItems.filter((item) => {
                    return item[col] === value;
                });
            }
        });

        // Filter search - indexes all columns
        filteredItems = filteredItems.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value)
                    .toLowerCase()
                    .includes(filterText.toLowerCase());
            });
        });

        this.setState({ filteredItems, loading: false });
    };

    render() {
        const { filterText, filteredItems, loading, filterColumns } =
            this.state;
        const {
            section,
            columns,
            expandableRows,
            expandableRowsComponent,
            expandableRowsComponentProps,
            theme,
        } = this.props;

        let pagination = false;
        let paginationPerPage = 50;
        if (section.data.length > 25) {
            pagination = true;
        }

        return (
            <DataTable
                title={section.header_name}
                columns={columns}
                data={filteredItems}
                noDataComponent='No records match your search'
                progressPending={loading}
                progressComponent={
                    <LoadingSpinner classes='flex justify-center p-2' />
                }
                fixedHeader
                fixedHeaderScrollHeight='calc(100vh - 175px)'
                pagination={pagination}
                paginationPerPage={paginationPerPage}
                paginationRowsPerPageOptions={[25, 50, 100]}
                subHeader
                subHeaderComponent={
                    <Filter
                        onFilter={(e) => this.setFilterText(e.target.value)}
                        onClear={this.handleClear}
                        filterText={filterText}
                        filterColumns={filterColumns}
                    />
                }
                expandableRows={expandableRows}
                expandableRowsComponent={expandableRowsComponent}
                expandableRowsComponentProps={expandableRowsComponentProps}
                customStyles={customStyles}
                theme={theme === 'dark' ? 'dark' : 'light'}
                striped
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(DataTableFilter);
