const { isNullUndefined } = require('./general');

const numericSort = (rowA, rowB, columnSelector) => {
    const a = Number(rowA[columnSelector]);
    const b = Number(rowB[columnSelector]);

    /**
     * The Number() cast will return NaN if value is text (e.g. "DQ")
     * Be sure to always place DQ'd runners at end of list (or the top of the
     * list if it's in descending order)
     */
    if (a > b || (isNaN(a) && !isNaN(b))) {
        return 1;
    }
    if (a < b || (isNaN(b) && !isNaN(a))) {
        return -1;
    }
    return 0;
};

const nielsonDifferenceSort = (rowA, rowB, columnSelector) => {
    const a = rowA[columnSelector];
    const b = rowB[columnSelector];
    const aSign = a[0];
    const bSign = b[0];

    if (aSign === '+' && bSign === '-') {
        return 1;
    }

    if (aSign === '-' && bSign === '+') {
        return -1;
    }

    const aTime = a.slice(1);
    const bTime = b.slice(1);

    if (aSign === '+' && bSign === '+') {
        if (aTime > bTime) {
            return 1;
        }
        if (aTime < bTime) {
            return -1;
        }
        return 0;
    }

    if (aSign === '-' && bSign === '-') {
        if (aTime > bTime) {
            return -1;
        }
        if (aTime < bTime) {
            return 1;
        }
        return 0;
    }

    return 0;
};

const generateDataTablesColumns = (columns, data) => {
    if (isNullUndefined(columns) || isNullUndefined(data)) {
        return null;
    }
    const newColumns = []; // Holds final updated columns
    const colWidthsRem = []; // Column widths in rem
    // Array of objects with column order and column width in pixels
    const colWidthsOrder = [];

    // Determine column widths
    columns.forEach((column) => {
        // Determine column width by determining largest piece of data
        let colNameLength = column.name.length;
        // Add 1.25 to column name / header to account for sorting arrow
        if (column.sortable) {
            colNameLength += 1.25;
        }

        // Find longest piece of data in column
        let maxCellLength = 0;
        data.forEach((item) => {
            if (String(item[column.selector]).length > maxCellLength) {
                maxCellLength = String(item[column.selector]).length;
            }
        });
        /**
         * Compare column name / header with column data
         * Each letter (16px yorkten-slab-normal font-weight 600 uppercase) is
         * about 0.7rem wide
         * Each letter (12.8px aktiv-grotesk font-wegith 400 uppercase) is
         * about 0.4rem wide
         * Add 2 rem for 16px padding on either side
         */
        const headerCellWidth = colNameLength * 0.7 + 2;
        const cellWidth = maxCellLength * 0.4 + 2;
        let finalWidth =
            headerCellWidth > cellWidth ? headerCellWidth : cellWidth;

        colWidthsRem.push(finalWidth);
        if (String(column.order) !== 'null') {
            colWidthsOrder.push({
                widthPx: finalWidth * 16, // Convert from rem to pixels
                order: column.order,
            });
        }
    });

    // Sort column widths in column order by extracting the width from the object
    colWidthsOrder.sort((a, b) => {
        return Number(a.order) < Number(b.order)
            ? -1
            : Number(a.order) === Number(b.order)
            ? 0
            : 1;
    });
    const colWidthsPx = colWidthsOrder.map((obj) => obj.widthPx);

    // Account for expandable component dropdown arrow width (48px)
    const expandableArrowWidth = 48;
    /**
     * Account for browser scroll bars on desktop devices
     * Max browser scroll bar width is 17px
     * Note: even though we style the scrollbars to be 6px (and Firefox's are
     * about 8px) the extra value gives us a bit of extra space to make sure we
     * don't have horizontal scrollbars anywhere
     * 2 scroll bars for main page and results section
     */
    let scrollbarWidth = 0;
    if (window.innerWidth > 1000) {
        scrollbarWidth = 17 * 2;
    }

    // Generate new column data
    columns.forEach((column, idx) => {
        // Determine breakpoint at which to show column
        let hideWidth = 0;
        if (column.order && String(column.order) !== 'null') {
            const sliceIdx = column.order;
            const sumArr = colWidthsPx.slice(0, sliceIdx);
            if (sumArr.length > 1) {
                hideWidth =
                    Math.ceil(
                        sumArr.reduce(
                            (prevValue, currentValue) =>
                                prevValue + currentValue
                        )
                    ) +
                    expandableArrowWidth +
                    scrollbarWidth;
            }
        }

        // Omit the additional info column (results data only)
        let omit = false;
        if (String(column.selector).toLowerCase() === 'additional_info') {
            omit = true;
        }

        // Determine sort function
        let sortFunction = null;
        switch (column.sortMethod) {
            case 'alphabetic':
                // React Data Tables sorts alphabetically by default
                // Alphabetic sort works fine for times (except for Nielson difference)
                break;

            case 'numeric':
                sortFunction = (a, b) => numericSort(a, b, column.selector);
                break;

            case 'nielsonDifference':
                sortFunction = (a, b) =>
                    nielsonDifferenceSort(a, b, column.selector);
                break;

            default:
                break;
        }

        newColumns.push({
            id: column.name,
            name: column.name.toUpperCase(),
            selector: (row) => row[column.selector],
            sortable: column.sortable,
            minWidth: `${colWidthsRem[idx]}rem`,
            wrap: true,
            omit: omit,
            hide: hideWidth,
            sortFunction: sortFunction,
        });
    });

    return newColumns;
};

module.exports = {
    generateDataTablesColumns,
};
