const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getCourseRecords = (active = true) => {
    const query = qs.stringify({
        filters: {
            is_active: {
                $eq: active,
            },
        },
        sort: ['course_link_name:asc'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/course-records?${query}`,
    });
};

const getCourseRecord = (courseRecordId) => {
    const query = qs.stringify({
        populate: {
            result: '*',
        },
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/course-records/${courseRecordId}?${query}`,
    });
};

const getCourseRecordsPage = () => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/course-records-page`,
    });
};

const getPersonalCourseRecords = (active = true) => {
    const query = qs.stringify({
        filters: {
            is_active: {
                $eq: active,
            },
        },
        sort: ['course_link_name:asc'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/personal-course-records?${query}`,
    });
};

const getPersonalCourseRecord = (personalCourseRecordId) => {
    const query = qs.stringify({
        populate: {
            result: '*',
        },
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/personal-course-records/${personalCourseRecordId}?${query}`,
    });
};

module.exports = {
    getCourseRecords,
    getCourseRecord,
    getCourseRecordsPage,
    getPersonalCourseRecords,
    getPersonalCourseRecord,
};
