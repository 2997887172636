// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import ResultsDisplay from './ResultsDisplay';
import ListBox from '../../components/ListBox/ListBox';

// Services, data, and media
import { getResults } from '../../services/resultsService';
import { groupResultsByMonthOrYear } from '../../utils/results';
import { availableYears } from '../../utils/datesAndTimes';
import { isNullUndefined } from '../../utils/general';
import {
    setMonthDisclosures,
    setResults,
    setScrollPosition,
    setSelectedYear,
} from '../../redux/actions/state';

class ResultsPage extends Component {
    state = {
        loading: true,
        error: false,
        resultsData: [],
        resultsGrouped: null,
        selectedYear: availableYears[0],
        monthDisclosures: {
            January: false,
            February: false,
            March: false,
            April: false,
            May: false,
            June: false,
            July: false,
            August: false,
            September: false,
            October: false,
            November: false,
            December: false,
        },
    };

    componentDidMount() {
        let loadFromRedux = false;
        if (this.props.location.state) {
            loadFromRedux = this.props.location.state.loadFromRedux;
            /* Clear state from location history so we get new 
            data when we refresh the page */
            this.props.history.replace({ state: {} });
        }

        if (loadFromRedux) {
            this.setResultsFromRedux();
            this.setMonthDisclosuresFromRedux();
            this.setSelectedYearFromRedux();
            this.setState({ loading: false }, () =>
                window.scrollTo({
                    top: this.props.scrollPosition,
                    left: 0,
                    behavior: 'auto',
                })
            );
        } else {
            this.getResultsData();
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        this.updateReduxResults();
        this.updateReduxMonthDisclosures();
        this.updateReduxSelectedYear();
        this.props.setScrollPosition(window.scrollY, 'Results');
    }

    setSelectedYear = (year) => {
        this.setState(
            { selectedYear: year, loading: true },
            this.getResultsData
        );
    };

    getResultsData() {
        getResults(this.state.selectedYear)
            .then((response) => {
                this.setState(
                    {
                        loading: false,
                        resultsData: response.data.data,
                    },
                    this.groupResults
                );
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    groupResults() {
        const { resultsData } = this.state;

        const resultsGrouped = groupResultsByMonthOrYear(resultsData, true);

        if (!isNullUndefined(resultsGrouped)) {
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        resultsGrouped,
                        monthDisclosures: {
                            January: false,
                            February: false,
                            March: false,
                            April: false,
                            May: false,
                            June: false,
                            July: false,
                            August: false,
                            September: false,
                            October: false,
                            November: false,
                            December: false,
                        },
                    };
                },
                () => {
                    const month = resultsGrouped.keys().next().value;
                    this.toggleDisclosure(month);
                    this.updateReduxResults();
                }
            );
        } else {
            this.setState({ resultsGrouped: null });
        }
    }

    // Handle disclosure visibility to open most recent month
    toggleDisclosure = (month) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                monthDisclosures: {
                    ...prevState.monthDisclosures,
                    [month]: !prevState.monthDisclosures[month],
                },
            };
        });
    };

    updateReduxResults = () => {
        const { resultsGrouped } = this.state;
        this.props.setResults(resultsGrouped);
    };

    updateReduxMonthDisclosures = () => {
        const { monthDisclosures } = this.state;
        this.props.setMonthDisclosures(monthDisclosures);
    };

    updateReduxSelectedYear = () => {
        const { selectedYear } = this.state;
        this.props.setSelectedYear(selectedYear);
    };

    setResultsFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                resultsGrouped: this.props.reduxResults,
            };
        });
    };

    setMonthDisclosuresFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                monthDisclosures: this.props.reduxMonthDisclosures,
            };
        });
    };

    setSelectedYearFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedYear: this.props.reduxSelectedYear,
            };
        });
    };

    render() {
        const {
            resultsGrouped,
            monthDisclosures,
            loading,
            error,
            selectedYear,
        } = this.state;

        return (
            <>
                <div className='w-full mx-auto p-4 py-0 max-w-3xl'>
                    <div className='flex flex-wrap justify-between items-center mb-2'>
                        <h2 className='text-4xl font-bold py-2'>
                            {selectedYear} Race Results
                        </h2>
                        <div className='relative w-full sm:w-0 h-14 py-2'>
                            <ListBox
                                options={availableYears}
                                onChange={this.setSelectedYear}
                                selectedOption={selectedYear}
                                widthClass='w-28'
                            />
                        </div>
                    </div>
                    <hr className='mb-2 border-gray-300 dark:border-gray-700' />
                </div>
                <ResultsDisplay
                    resultsGrouped={resultsGrouped}
                    monthDisclosures={monthDisclosures}
                    toggleDisclosure={this.toggleDisclosure}
                    loading={loading}
                    error={error}
                    selectedYear={selectedYear}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reduxResults: state.state.results,
        reduxMonthDisclosures: state.state.monthDisclosures,
        scrollPosition: state.state.scrollPositionResults,
        reduxSelectedYear: state.state.selectedYear,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setResults: (results) => dispatch(setResults(results)),
        setMonthDisclosures: (monthDisclosures) =>
            dispatch(setMonthDisclosures(monthDisclosures)),
        setScrollPosition: (scrollPosition, page) =>
            dispatch(setScrollPosition(scrollPosition, page)),
        setSelectedYear: (selectedYear) =>
            dispatch(setSelectedYear(selectedYear)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);
