// Libraries
import React, { Component } from 'react';

// Components
import SponsorsCarousel from '../../components/SponsorsCarousel/SponsorsCarousel';
import DataTableFilter from '../../components/DataTableFilter/DataTableFilter';
import DataTableDetail from '../../components/DataTableDetail/DataTableDetail';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
    getCourseRecord,
    getPersonalCourseRecord,
} from '../../services/courseRecordsService';
import { generateDataTablesColumns } from '../../utils/dataTablesColumns';
import { isNullUndefined } from '../../utils/general';

class CourseRecordPage extends Component {
    state = {
        loading: true,
        error: false,
        courseRecordData: {},
        showCourseRecordsNavigation: false,
    };

    componentDidMount() {
        let showCourseRecordsNavigation = false;
        if (this.props.location.state) {
            showCourseRecordsNavigation =
                this.props.location.state.showCourseRecordsNavigation;
            if (showCourseRecordsNavigation) {
                this.setState({ showCourseRecordsNavigation });
            }
        }
        this.getCourseRecordData(this.props.match.params.id);
        window.scrollTo(0, 0);
    }

    getCourseRecordData = (courseId) => {
        let getFunction = this.props.isPersonal
            ? getPersonalCourseRecord
            : getCourseRecord;
        getFunction(courseId)
            .then((response) => {
                this.setState({
                    loading: false,
                    courseRecordData: response.data.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    };

    onBack = () => {
        this.props.history.push({
            pathname: this.props.isPersonal
                ? '/personal-course-records'
                : '/course-records',
            state: {
                loadFromRedux: true,
            },
        });
    };

    render() {
        const {
            loading,
            error,
            courseRecordData,
            showCourseRecordsNavigation,
        } = this.state;

        const { isPersonal } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }
        if (error || isNullUndefined(courseRecordData)) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        const { result } = courseRecordData.attributes;

        if (!isNullUndefined(result)) {
            const {
                sections,
                title,
                inaugural_race_year,
                course_used_in_years,
                course_info,
                notes,
            } = result?.data?.attributes;

            let dataTableSections = null;
            if (!isNullUndefined(sections)) {
                dataTableSections = sections.map((section, idx) => {
                    const newColumns = generateDataTablesColumns(
                        section.columns,
                        section.data
                    );

                    // Pass columns data so the ResultsDetail component can sort fields by order
                    const columnsPropsObj = {
                        columns: section.columns,
                    };

                    return (
                        <DataTableFilter
                            key={idx}
                            section={section}
                            columns={newColumns}
                            expandableRows={true}
                            expandableRowsComponent={DataTableDetail}
                            expandableRowsComponentProps={columnsPropsObj}
                        />
                    );
                });
            }

            return (
                <>
                    <div className='w-full mx-auto p-4 max-w-5xl'>
                        {showCourseRecordsNavigation ? (
                            <div
                                className='flex items-center cursor-pointer mb-2'
                                onClick={this.onBack}
                            >
                                <ArrowLeftIcon className='h-8 w-8 heroicon-stroke-w-2.5 text-gray-500' />
                                <span className='ml-2 font-serif font-semibold text-lg text-gray-500'>
                                    ALL {isPersonal ? 'PERSONAL' : ''} COURSE
                                    RECORDS
                                </span>
                            </div>
                        ) : null}
                        <div className='prose dark:prose-invert max-w-5xl'>
                            <h1>{title}</h1>
                            {!isNullUndefined(inaugural_race_year) &&
                            inaugural_race_year !== '' ? (
                                <p>
                                    <strong>Inaugural Race Year: </strong>
                                    {inaugural_race_year}
                                </p>
                            ) : null}
                            {!isNullUndefined(course_used_in_years) &&
                            course_used_in_years !== '' ? (
                                <p>
                                    <strong>Course Used in Years: </strong>
                                    {course_used_in_years}
                                </p>
                            ) : null}
                            {!isNullUndefined(course_info) &&
                            course_info !== '' ? (
                                <p>
                                    <strong>Course Information: </strong>
                                    {course_info}
                                </p>
                            ) : null}
                            {!isNullUndefined(notes) && notes !== '' ? (
                                <p>
                                    <strong>Notes: </strong>
                                    {notes}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <SponsorsCarousel />
                    {!isNullUndefined(dataTableSections) ? (
                        <div className='w-full mx-auto space-y-2 py-2'>
                            {dataTableSections}
                        </div>
                    ) : null}
                </>
            );
        }
        return null;
    }
}

export default CourseRecordPage;
