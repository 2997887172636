const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getRunningGroups = () => {
    const query = qs.stringify({
        populate: ['links', 'photo', 'contacts'],
        sort: ['name:asc'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/running-groups?${query}`,
    });
};

module.exports = {
    getRunningGroups,
};
