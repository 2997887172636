// Libraries
import ReactMarkdown from 'react-markdown';

// Components
import LinkButton from '../../components/LinkButton/LinkButton';
import ResponsiveMedia from '../../components/ResponsiveMedia/ResponsiveMedia';

// Services, data, and media
import { classNames, isNullUndefined } from '../../utils/general';
import {
    getPhotoPositionClass,
    getBackgroundGradientClass,
} from '../../utils/styles';
const gfm = require('remark-gfm');

const CarouselCard = ({ cardData }) => {
    if (isNullUndefined(cardData) || isNullUndefined(cardData.attributes)) {
        return null;
    }
    const { photo_position, links, headline, description } =
        cardData.attributes;
    const photo = cardData.attributes.photo?.data;
    const photoPositionClass = getPhotoPositionClass(photo_position);

    const linkButtons = links.map((link) => (
        <LinkButton link={link} key={link.id} />
    ));

    const backgroundGradientClass = getBackgroundGradientClass(
        photo,
        cardData.id
    );

    return (
        <div className='bg-gray-50 dark:bg-gray-800 h-full rounded-xl shadow-xl overflow-hidden'>
            {photo ? (
                <ResponsiveMedia
                    media={photo}
                    classes={classNames(
                        photoPositionClass,
                        'h-48 w-full bg-no-repeat bg-cover'
                    )}
                    backgroundImage={true}
                />
            ) : (
                <div
                    className={classNames(
                        'h-48 w-full',
                        backgroundGradientClass
                    )}
                ></div>
            )}
            <div className='p-4 prose dark:prose-invert h-56 overflow-auto'>
                {headline ? (
                    <h4 className='font-serif font-semibold'>{headline}</h4>
                ) : null}
                {description ? (
                    <ReactMarkdown
                        children={description}
                        remarkPlugins={[gfm]}
                        linkTarget={(href, children, title) =>
                            href.includes('http') ? '_blank' : null
                        }
                    />
                ) : null}
            </div>
            {linkButtons.length > 0 ? (
                <div className='flex flex-wrap items-start -ml-1 -mt-1 p-4'>
                    {linkButtons}
                </div>
            ) : (
                <div className='h-[5.25rem]'></div>
            )}
        </div>
    );
};

export default CarouselCard;
