// Libraries
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

// Services, data, and media
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { setTheme } from '../../redux/actions/state';
import { getThemeMode } from '../../utils/themeSwitcher';
import { classNames } from '../../utils/general';

const ThemeSwitcher = ({ setReduxTheme }) => {
    const [theme, setLocalTheme] = useState(getThemeMode());
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // Don't set theme when component mounts
            setReduxTheme(theme);
        }
    }, [theme, setReduxTheme]);

    let selectedThemeIcon;
    if (theme === 'light') {
        selectedThemeIcon = (
            <SunActiveIcon className='mr-2 h-5 w-5' aria-hidden='true' />
        );
    } else if (theme === 'dark') {
        selectedThemeIcon = (
            <MoonActiveIcon className='mr-2 h-5 w-5' aria-hidden='true' />
        );
    } else {
        selectedThemeIcon = (
            <SystemActiveIcon className='mr-2 h-5 w-5' aria-hidden='true' />
        );
    }

    return (
        <div>
            <Menu as='div' className='relative inline-block text-left'>
                {({ open }) => (
                    <>
                        <div className='w-full flex items-center'>
                            <span className='font-serif font-semibold mr-3'>
                                Switch Theme
                            </span>
                            <Menu.Button className='inline-flex justify-center items-center rounded-md border border-gray-500 px-4 py-2 text-sm hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none'>
                                {selectedThemeIcon}
                                {theme.charAt(0).toUpperCase() + theme.slice(1)}
                                {open ? (
                                    <ChevronUpIcon
                                        className='ml-1 h-4 w-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <ChevronDownIcon
                                        className='ml-1 h-4 w-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                )}
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                        >
                            <Menu.Items className='absolute bottom-9 right-0 mb-2 p-1 origin-bottom-left rounded-md bg-gray-50 dark:bg-gray-900 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 focus:outline-none'>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? 'bg-gray-900 dark:bg-gray-50 text-gray-50 dark:text-gray-900'
                                                    : 'text-gray-900 dark:text-gray-50',
                                                'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                theme === 'light'
                                                    ? 'bg-gray-200'
                                                    : ''
                                            )}
                                            onClick={() =>
                                                setLocalTheme('light')
                                            }
                                        >
                                            {active || theme === 'light' ? (
                                                <SunActiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            ) : (
                                                <SunInactiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            )}
                                            Light
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? 'bg-gray-900 dark:bg-gray-50 text-gray-50 dark:text-gray-900'
                                                    : 'text-gray-900 dark:text-gray-50',
                                                'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                theme === 'dark'
                                                    ? 'bg-gray-800'
                                                    : ''
                                            )}
                                            onClick={() =>
                                                setLocalTheme('dark')
                                            }
                                        >
                                            {active || theme === 'dark' ? (
                                                <MoonActiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            ) : (
                                                <MoonInactiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            )}
                                            Dark
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? 'bg-gray-900 dark:bg-gray-50 text-gray-50 dark:text-gray-900'
                                                    : 'text-gray-900 dark:text-gray-50',
                                                'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                theme === 'system'
                                                    ? 'bg-gray-200 dark:bg-gray-800'
                                                    : ''
                                            )}
                                            onClick={() =>
                                                setLocalTheme('system')
                                            }
                                        >
                                            {active || theme === 'system' ? (
                                                <SystemActiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            ) : (
                                                <SystemInactiveIcon
                                                    className='mr-2 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            )}
                                            System
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReduxTheme: (theme) => dispatch(setTheme(theme)),
    };
};

export default connect(null, mapDispatchToProps)(ThemeSwitcher);

function SunInactiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='2'
        >
            <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z'
            />
        </svg>
    );
}

function SunActiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
        >
            <path
                fillRule='evenodd'
                d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
                clipRule='evenodd'
            />
        </svg>
    );
}

function MoonInactiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='2'
        >
            <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z'
            />
        </svg>
    );
}

function MoonActiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
        >
            <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z' />
        </svg>
    );
}

function SystemInactiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='2'
        >
            <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
            />
        </svg>
    );
}

function SystemActiveIcon(props) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
        >
            <path
                fillRule='evenodd'
                d='M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z'
                clipRule='evenodd'
            />
        </svg>
    );
}
