/**
 * Fetch user's theme setting from local storage.
 * Theme setting is 'system' if there's nothing in local storage.
 * @returns theme ('light', 'dark', 'system')
 */
const getThemeMode = () => {
    if ('theme' in localStorage) {
        return localStorage.theme;
    }
    return 'system';
};

/**
 * Checks system media settings and local storage to determine theme.
 * If 'theme' is not in local storage go with system settings.
 * If 'theme' is in local storage go with the saved theme.
 * Add 'dark' class at top of HTML tree to enable or disable dark mode
 * depending on the theme.
 * @returns theme ('light' or 'dark')
 */
const setTheme = () => {
    if (
        localStorage.getItem('theme') === 'dark' ||
        (!('theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
        document.documentElement.classList.add('dark');
        return 'dark';
    }
    document.documentElement.classList.remove('dark');
    return 'light';
};

/**
 * Store user selected theme in local storage.
 * If user selects 'system' we remove the 'theme' item from local storage.
 * @param {string} theme ('light', 'dark', 'system')
 */
const setThemeInLocalStorage = (theme) => {
    if (theme === 'light') {
        localStorage.theme = 'light';
    } else if (theme === 'dark') {
        localStorage.theme = 'dark';
    } else {
        localStorage.removeItem('theme');
    }
};

module.exports = {
    getThemeMode,
    setTheme,
    setThemeInLocalStorage,
};
