// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

const eventDetailResultsDisplay = (props) => {
    const { resultsData } = props;

    /*
        Group results by division and stats
        {
            "divisions": {
                "division_id_1": [result1, result2, ...],
                "division_id_2": [result1, result2, ...]
            },
            "stats": [result1, result2, ...]
        }
    */
    const resultsGrouped = new Map();

    resultsData.forEach((result) => {
        const { division_id, is_stats } = result.attributes;
        const divisionId = division_id;
        const isStats = is_stats;

        if (!resultsGrouped.has('divisions')) {
            resultsGrouped.set('divisions', new Map());
            resultsGrouped.set('stats', []);
        }

        if (isStats) {
            resultsGrouped.get('stats').push(result);
        } else if (!resultsGrouped.get('divisions').has(divisionId)) {
            resultsGrouped.get('divisions').set(divisionId, [result]);
        } else {
            resultsGrouped.get('divisions').get(divisionId).push(result);
        }
    });

    if (resultsGrouped.size > 0) {
        const divisions = [];
        resultsGrouped.get('divisions').forEach((resultsArray, division) => {
            // Sort results alphabetically by link name
            const sortedResultsArray = resultsArray.sort((result1, result2) => {
                if (
                    result1.attributes.link_name < result2.attributes.link_name
                ) {
                    return -1;
                } else if (
                    result1.attributes.link_name > result2.attributes.link_name
                ) {
                    return 1;
                } else {
                    return 0;
                }
            });
            const results = [];
            sortedResultsArray.forEach((result) => {
                const { credit, link, link_name, result_id } =
                    result.attributes;
                results.push(
                    <li key={result_id}>
                        {link && link !== '' ? (
                            <a
                                className='hover:underline'
                                href={link}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Results by {credit}
                            </a>
                        ) : (
                            <Link
                                to={`/results/${result_id}`}
                                className='hover:underline'
                            >
                                {link_name}
                            </Link>
                        )}
                    </li>
                );
            });
            divisions.push(<ul key={division}>{results}</ul>);
        });

        const stats = [];
        const statsArray = resultsGrouped.get('stats');
        // Sort stats alphabetically by link name
        const sortedStatsArray = statsArray.sort((stat1, stat2) => {
            if (stat1.attributes.link_name < stat2.attributes.link_name) {
                return -1;
            } else if (
                stat1.attributes.link_name > stat2.attributes.link_name
            ) {
                return 1;
            } else {
                return 0;
            }
        });

        sortedStatsArray.forEach((stat) => {
            const { link_name, result_id } = stat.attributes;
            stats.push(
                <li key={result_id}>
                    <Link
                        to={`/results/${result_id}`}
                        className='hover:underline'
                    >
                        {link_name}
                    </Link>
                </li>
            );
        });

        return (
            <div>
                <h5 className='font-serif font-bold'>Results</h5>
                <div>{divisions}</div>
                {stats.length > 0 ? (
                    <>
                        <h5 className='font-serif font-bold'>Other Stats</h5>
                        <ul>{stats}</ul>
                    </>
                ) : null}
            </div>
        );
    }

    return null;
};

export default eventDetailResultsDisplay;
