import { Link } from 'react-router-dom';
import { classNames } from '../../../utils/general';

const ctaButtons = ({ ctaButtons, toggleDisclosure }) =>
    ctaButtons.map((button) => {
        if (!button || !button.attributes) {
            return null;
        }
        const { color, external, name, slug } = button.attributes;
        const classes = classNames(
            color === 'gray' ? 'bg-gray-700' : `bg-${color}`,
            color === 'yellow' ? 'text-gray-900' : 'text-gray-50',
            `px-3 py-3 rounded-md text-base font-serif font-semibold`
        );
        return external ? (
            <a
                key={button.id}
                href={slug}
                target='_blank'
                rel='noopener noreferrer'
                className={classes}
            >
                {name.toUpperCase()}
            </a>
        ) : (
            <Link
                key={button.id}
                to={slug}
                onClick={toggleDisclosure}
                className={classes}
            >
                {name.toUpperCase()}
            </Link>
        );
    });

export default ctaButtons;
