// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';

// Services, data, and media
import { getFullWrittenDate } from '../../utils/datesAndTimes';
import { isNullUndefined } from '../../utils/general';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const historicalResultsDisplay = (props) => {
    const { resultsGrouped, yearDisclosures } = props;

    if (!isNullUndefined(resultsGrouped) && resultsGrouped.size > 0) {
        const years = [];
        resultsGrouped.forEach((eventIdMap, year) => {
            const events = [];
            eventIdMap.forEach((divisionsStatsMap, eventId) => {
                let eventMetadata = null;
                const divisions = [];
                divisionsStatsMap
                    .get('divisions')
                    .forEach((resultsArray, division) => {
                        // Get event info from first result in result array
                        eventMetadata = resultsArray[0];
                        // Sort results alphabetically by link name
                        const sortedResultsArray = resultsArray.sort(
                            (result1, result2) => {
                                const result1LinkName =
                                    result1.attributes.link_name;
                                const result2LinkName =
                                    result2.attributes.link_name;
                                if (
                                    !isNullUndefined(result1LinkName) &&
                                    !isNullUndefined(result2LinkName) &&
                                    result1LinkName < result2LinkName
                                ) {
                                    return -1;
                                } else if (
                                    !isNullUndefined(result1LinkName) &&
                                    !isNullUndefined(result2LinkName) &&
                                    result1LinkName > result2LinkName
                                ) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        );
                        const results = [];
                        sortedResultsArray.forEach((result) => {
                            const { result_id, link, link_name, credit } =
                                result.attributes;
                            if (
                                isNullUndefined(result_id) ||
                                isNullUndefined(link_name)
                            ) {
                                return;
                            }
                            results.push(
                                <li key={result.id} className='hover:underline'>
                                    {link && link !== '' ? (
                                        <a
                                            href={link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Results by {credit}
                                        </a>
                                    ) : (
                                        <Link to={`/results/${result_id}`}>
                                            {link_name}
                                        </Link>
                                    )}
                                </li>
                            );
                        });
                        divisions.push(<ul key={division}>{results}</ul>);
                    });

                const stats = [];
                const statsArray = divisionsStatsMap.get('stats');
                if (eventMetadata == null && statsArray.length > 0) {
                    eventMetadata = statsArray[0];
                }
                // Sort stats alphabetically by link name
                const sortedStatsArray = statsArray.sort((stat1, stat2) => {
                    const stat1LinkName = stat1.attributes.link_name;
                    const stat2LinkName = stat2.attributes.link_name;
                    if (
                        !isNullUndefined(stat1LinkName) &&
                        !isNullUndefined(stat2LinkName) &&
                        stat1LinkName < stat2LinkName
                    ) {
                        return -1;
                    } else if (
                        !isNullUndefined(stat1LinkName) &&
                        !isNullUndefined(stat2LinkName) &&
                        stat1LinkName > stat2LinkName
                    ) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                sortedStatsArray.forEach((stat) => {
                    const { result_id, link_name } = stat.attributes;
                    if (
                        isNullUndefined(result_id) ||
                        isNullUndefined(link_name)
                    ) {
                        return;
                    }
                    stats.push(
                        <li key={stat.id} className='hover:underline'>
                            <Link to={`/results/${result_id}`}>
                                {link_name}
                            </Link>
                        </li>
                    );
                });

                const { event_name, race_date } = eventMetadata.attributes;
                if (isNullUndefined(event_name) || isNullUndefined(race_date)) {
                    return;
                }

                events.push(
                    <div key={eventId}>
                        <h4 className='font-bold'>{event_name}</h4>
                        <p className='italic'>
                            {getFullWrittenDate(race_date)}
                        </p>
                        {divisions.length > 0 ? <div>{divisions}</div> : null}
                        {stats.length > 0 ? (
                            <>
                                <h5 className='font-bold'>Other Stats</h5>
                                <ul>{stats}</ul>
                            </>
                        ) : null}
                    </div>
                );
            });

            years.push(
                <Disclosure key={year}>
                    <Disclosure.Button as='div' className='appearance-none'>
                        <div
                            className='hover:cursor-pointer flex items-center justify-between'
                            onClick={() => props.toggleDisclosure(year)}
                        >
                            <h2 className='font-bold'>{year}</h2>
                            {yearDisclosures[year] ? (
                                <ChevronUpIcon
                                    className='h-8 w-8 heroicon-stroke-w-3'
                                    aria-hidden='true'
                                />
                            ) : (
                                <ChevronDownIcon
                                    className='h-8 w-8 heroicon-stroke-w-3'
                                    aria-hidden='true'
                                />
                            )}
                        </div>
                    </Disclosure.Button>
                    {yearDisclosures[year] ? (
                        <Disclosure.Panel static>
                            <div className='space-y-3'>{events}</div>
                        </Disclosure.Panel>
                    ) : null}
                    <hr className='my-2 border-gray-300 dark:border-gray-700' />
                </Disclosure>
            );
        });

        return (
            <div className='not-prose space-y-3 text-gray-900 dark:text-gray-50'>
                {years}
            </div>
        );
    }
    return <h3>No results available</h3>;
};

export default historicalResultsDisplay;
