// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { classNames, shuffleArray } from '../../utils/general';
import { getSponsors } from '../../services/sponsorsService';
import { getISODate } from '../../utils/datesAndTimes';
import { setSponsors } from '../../redux/actions/state';

class SponsorsCarousel extends Component {
    state = {
        loading: true,
        error: false,
        sponsorsData: [],
        sponsorsDataDouble: [],
        index: 0,
        timer: null,
    };

    componentDidMount() {
        if (this.props.reduxSponsorsData.length > 0) {
            // Load sponsors from Redux if we've already fetched them
            this.setSponsorsFromRedux();
            this.setState({ loading: false }, this.setTimer);
        } else {
            // Fetch sponsors from Strapi
            const date = getISODate();
            getSponsors(date)
                .then((response) => {
                    const data = response.data?.data;
                    this.setState(
                        {
                            loading: false,
                            sponsorsData: data,
                            sponsorsDataDouble: [...data, ...data],
                        },
                        this.sortAndRandomizeSponsors
                    );
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: true,
                    });
                });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    sortAndRandomizeSponsors = () => {
        const { sponsorsData } = this.state;
        const paidSponsors = sponsorsData.filter(
            (sponsor) => sponsor.attributes.is_paid
        );
        shuffleArray(paidSponsors);
        const unpaidSponsors = sponsorsData.filter(
            (sponsor) => !sponsor.attributes.is_paid
        );
        shuffleArray(unpaidSponsors);
        const newSponsorsData = [...paidSponsors, ...unpaidSponsors];
        this.setState(
            {
                sponsorsData: newSponsorsData,
                sponsorsDataDouble: [...newSponsorsData, ...newSponsorsData],
            },
            () => {
                this.updateReduxSponsors();
                this.setTimer();
            }
        );
    };

    setSponsorsFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                sponsorsData: this.props.reduxSponsorsData,
                sponsorsDataDouble: [
                    ...this.props.reduxSponsorsData,
                    ...this.props.reduxSponsorsData,
                ],
            };
        });
    };

    updateReduxSponsors = () => {
        const { sponsorsData } = this.state;
        this.props.setSponsors(sponsorsData);
    };

    progressCarousel = () => {
        const { index, sponsorsData } = this.state;

        let newIndex;
        if (index >= sponsorsData.length) {
            newIndex = 0;
        } else {
            newIndex = index + 1;
        }

        this.setState({ index: newIndex }, () => {
            if (newIndex === 0) {
                this.resetTimer();
            }
        });
    };

    setTimer = () => {
        this.setState({ timer: setInterval(this.progressCarousel, 5000) });
    };

    resetTimer = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
        setTimeout(() => {
            this.progressCarousel();
            this.setState({ timer: setInterval(this.progressCarousel, 5000) });
        }, 500);
    };

    getDiff = () => {
        // Calculate how much we move each sponsor (e.g. sponsor width)
        let diff;
        const screenWidthWithoutScrollbar =
            document.documentElement.clientWidth;
        let numSponsors;
        /**
         * Max width is 1280px (7xl)
         * 1423px is the value above which 90% of the width is >= 1280px
         * Therefore we don't take 90% of the value
         * 0.9 * width = 1280 => width = 1423
         */
        if (screenWidthWithoutScrollbar >= 1423) {
            // 5 sponsors
            numSponsors = 5;
            diff = 1280 / 5;
        } else if (window.innerWidth >= 1280) {
            // (xl) 5 sponsors 90% width of window / 5 = width * 0.9 * 0.2
            numSponsors = 5;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.2;
        } else if (window.innerWidth >= 1024) {
            // (lg) 4 sponsors 90% width of window / 4 = width * 0.9 * 0.25
            numSponsors = 4;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.25;
        } else if (window.innerWidth >= 768) {
            // (md) 3 sponsors 90% width of window / 3 = width * 0.9 * 1/3 = width * 0.3
            numSponsors = 3;
            diff = screenWidthWithoutScrollbar * 0.3;
        } else if (window.innerWidth >= 640) {
            // (sm) 2 sponsors 90% width of window / 2 = width * 0.9 * 0.5
            numSponsors = 2;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.5;
        } else {
            // 1 sponsor 90% width of window = width * 0.9
            numSponsors = 1;
            diff = screenWidthWithoutScrollbar * 0.9;
        }
        // Don't scroll carousel if the sponsors fit in the view
        if (this.state.sponsorsData.length <= numSponsors) {
            diff = 0;
        }
        return [diff, numSponsors];
    };

    navigateToUrl = (url) => {
        if (url && url !== '') {
            if (url.includes('http')) {
                window.open(url, '_blank');
            } else {
                this.props.history.push(url);
            }
        }
    };

    render() {
        const { loading, error, sponsorsData, sponsorsDataDouble, index } =
            this.state;
        const { theme } = this.props;

        if (loading) {
            return <LoadingSpinner classes='w-full flex justify-center my-4' />;
        }

        if (error) {
            return (
                <div className='w-full flex justify-center my-4'>
                    <h3>Unable to load sponsors</h3>
                </div>
            );
        }

        if (sponsorsData.length === 0) {
            return null;
        }

        let [diff, numSponsors] = this.getDiff();
        let translateValue = index * -diff;

        let transitionClasses = '';
        if (index > 0) {
            transitionClasses = 'transition-transform duration-500 ease-in-out';
        }

        let dataArray = sponsorsDataDouble;
        if (sponsorsData.length <= numSponsors) {
            dataArray = sponsorsData;
        }

        let slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5';
        let slidesContainerWidthClass = 'w-full';
        if (sponsorsData.length === 4) {
            slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4';
            slidesContainerWidthClass = 'w-full xl:w-3/4';
        }
        if (sponsorsData.length === 3) {
            slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3';
            slidesContainerWidthClass = 'w-full lg:w-3/4 xl:w-2/3';
        }
        if (sponsorsData.length === 2) {
            slideWidthsClasses = 'w-full sm:w-1/2';
            slidesContainerWidthClass = 'w-full md:w-3/4 lg:w-2/3 xl:w-1/2';
        }
        if (sponsorsData.length === 1) {
            slideWidthsClasses = 'w-full';
            slidesContainerWidthClass =
                'w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-2/5';
        }

        const sponsors = dataArray.map((item, idx) => {
            const { url, media, dark_media } = item.attributes;
            let cursorClass = '';
            if (url && url !== '') {
                cursorClass = 'cursor-pointer';
            }
            return (
                <div
                    key={idx}
                    className={classNames(
                        slideWidthsClasses,
                        cursorClass,
                        transitionClasses,
                        'px-4 whitespace-normal inline-block align-middle'
                    )}
                    style={{
                        transform: `translateX(${translateValue}px)`,
                    }}
                    onClick={() => this.navigateToUrl(url)}
                >
                    {media ? (
                        <img
                            className='mx-auto'
                            src={
                                theme === 'dark' && dark_media?.data
                                    ? dark_media.data.attributes.url
                                    : media.data.attributes.url
                            }
                            alt={
                                theme === 'dark' && dark_media?.data
                                    ? dark_media.data.attributes.alternativeText
                                    : media.data.attributes.alternativeText
                            }
                        ></img>
                    ) : null}
                </div>
            );
        });

        return (
            <div className='w-full my-4'>
                <div className='w-[90%] max-w-7xl mx-auto my-4'>
                    <div
                        className={classNames(
                            slidesContainerWidthClass,
                            'mx-auto whitespace-nowrap overflow-hidden'
                        )}
                    >
                        {sponsors}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reduxSponsorsData: state.state.sponsors,
        theme: state.state.theme,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSponsors: (sponsors) => dispatch(setSponsors(sponsors)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsCarousel);
