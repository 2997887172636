// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Disclosure } from '@headlessui/react';

// Services, data, and media
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const mobileMenuItems = ({ loading, menuItems, toggleDisclosure }) => {
    if (loading) {
        return (
            <>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
                <div className='h-10 w-28 mx-auto rounded-md bg-gray-100 dark:bg-gray-800'></div>
            </>
        );
    }

    return menuItems.map((item) => {
        if (!item || !item?.attributes) {
            return null;
        }
        const { name, sub_menu_items, external, slug } = item.attributes;
        if (item.attributes?.sub_menu_items?.data?.length > 0) {
            return (
                <Disclosure key={item.id}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button
                                as='div'
                                className='menu-button hover:cursor-pointer flex items-center justify-center appearance-none'
                            >
                                <span className='sr-only'>{name}</span>
                                {name.toUpperCase()}
                                {open ? (
                                    <ChevronUpIcon
                                        className='ml-1 h-4 w-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                ) : (
                                    <ChevronDownIcon
                                        className='ml-1 h-4 w-4 heroicon-stroke-w-3'
                                        aria-hidden='true'
                                    />
                                )}
                            </Disclosure.Button>
                            <Disclosure.Panel>
                                {sub_menu_items.data.map((subMenuItem) => {
                                    const { external, slug, name } =
                                        subMenuItem.attributes;
                                    if (external) {
                                        return (
                                            <a
                                                key={subMenuItem.id}
                                                className='sub-menu-button'
                                                onClick={toggleDisclosure}
                                                href={slug}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                {name}
                                            </a>
                                        );
                                    }
                                    return (
                                        <Link
                                            key={subMenuItem.id}
                                            to={slug}
                                            onClick={toggleDisclosure}
                                            className='sub-menu-button'
                                        >
                                            {name}
                                        </Link>
                                    );
                                })}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            );
        }
        return (
            <div key={item.id}>
                {external ? (
                    <a
                        className='menu-button'
                        onClick={toggleDisclosure}
                        href={slug}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {name}
                    </a>
                ) : (
                    <Link
                        to={slug}
                        onClick={toggleDisclosure}
                        className='menu-button'
                    >
                        {name.toUpperCase()}
                    </Link>
                )}
            </div>
        );
    });
};

export default mobileMenuItems;
