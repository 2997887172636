const { getMonthName, getYear } = require('./datesAndTimes');
const { isNullUndefined } = require('./general');

/**
    Group results by month/year, event, and division or stats
    {
        "January": {
            "event_id_1": {
                "divisions": {
                    "division_id_1": [result1, result2, ...],
                    "division_id_2": [result1, result2, ...]
                },
                "stats": [result1, result2, ...]
            }
            "event_id_2": {
                "divisions": {
                    "division_id_1": [result1, result2, ...],
                    "division_id_2": [result1, result2, ...]
                },
                "stats": [result1, result2, ...]
            },
            ...
        },
        "February": {
            "event_id_1": {
                "divisions": {
                    "division_id_1": [result1, result2, ...],
                    "division_id_2": [result1, result2, ...]
                },
                "stats": [result1, result2, ...]
            },
            "event_id_2": {
                "divisions": {
                    "division_id_1": [result1, result2, ...],
                    "division_id_2": [result1, result2, ...]
                },
                "stats": [result1, result2, ...]
            },
            ...
        },
        ...
    }
 * @param {*} results results data from Strapi
 * @param {*} groupByMonth whether to group results by month.
                  If false, group results by year
 * @returns A Map of results data as shown above
 */
const groupResultsByMonthOrYear = (results, groupByMonth = false) => {
    if (!isNullUndefined(results) && results.length > 0) {
        const resultsGrouped = new Map();

        results.forEach((result) => {
            const { race_date, event_id, division_id, is_stats } =
                result.attributes;
            if (
                isNullUndefined(race_date) ||
                isNullUndefined(event_id) ||
                isNullUndefined(division_id) ||
                isNullUndefined(is_stats)
            ) {
                return;
            }
            const monthOrYear = groupByMonth
                ? getMonthName(race_date)
                : getYear(race_date);
            const eventId = event_id;
            const divisionId = division_id;
            const isStats = is_stats;

            if (!resultsGrouped.has(monthOrYear)) {
                resultsGrouped.set(monthOrYear, new Map());
            }
            if (!resultsGrouped.get(monthOrYear).has(eventId)) {
                resultsGrouped.get(monthOrYear).set(eventId, new Map());
                resultsGrouped
                    .get(monthOrYear)
                    .get(eventId)
                    .set('divisions', new Map());
                resultsGrouped.get(monthOrYear).get(eventId).set('stats', []);
            }

            if (isStats) {
                resultsGrouped
                    .get(monthOrYear)
                    .get(eventId)
                    .get('stats')
                    .push(result);
            } else if (
                !resultsGrouped
                    .get(monthOrYear)
                    .get(eventId)
                    .get('divisions')
                    .has(divisionId)
            ) {
                resultsGrouped
                    .get(monthOrYear)
                    .get(eventId)
                    .get('divisions')
                    .set(divisionId, [result]);
            } else {
                resultsGrouped
                    .get(monthOrYear)
                    .get(eventId)
                    .get('divisions')
                    .get(divisionId)
                    .push(result);
            }
        });

        return resultsGrouped;
    }
    return null;
};

module.exports = {
    groupResultsByMonthOrYear,
};
