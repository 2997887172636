const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getMenuItems = () => {
    const query = qs.stringify({
        populate: {
            sub_menu_items: {
                sort: ['order:asc'],
            },
        },
        sort: ['order:asc'],
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/menu-items?${query}`,
    });
};

const getCTAButtons = () => {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/cta-buttons`,
    });
};

module.exports = {
    getMenuItems,
    getCTAButtons,
};
