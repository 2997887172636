import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { isNullUndefined } from '../../utils/general';

const contactCard = ({ contact }) => {
    if (!isNullUndefined(contact) && contact?.attributes) {
        const { email, name, phone } = contact.attributes;
        return (
            <div className='p-3 border-2 rounded-lg m-1'>
                {name && name !== '' ? (
                    <h6 className='font-serif font-bold mb-2'>{name}</h6>
                ) : null}
                {email && email !== '' ? (
                    <div className='flex items-center mb-1'>
                        <div className='w-6 mr-2'>
                            <EnvelopeIcon
                                className='h-6 w-6 heroicon-stroke-w-2'
                                aria-hidden='true'
                            />
                        </div>
                        <a
                            className='my-1 break-all hover:underline'
                            href={`mailto:${email}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {email}
                        </a>
                    </div>
                ) : null}
                {phone && phone !== '' ? (
                    <div className='flex items-center mb-1'>
                        <div className='w-6 mr-2'>
                            <PhoneIcon
                                className='h-6 w-6 heroicon-stroke-w-2'
                                aria-hidden='true'
                            />
                        </div>
                        <span className='my-1'>{phone}</span>
                    </div>
                ) : null}
            </div>
        );
    }
    return null;
};

export default contactCard;
