// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import CtaButtons from '../CtaButtons/CtaButtons';
import DesktopMenuItems from '../DesktopMenuItems/DesktopMenuItems';

// Services, data, and media
import pprrLogoHeaderColor from '../../../assets/images/pprr-logos/pprr-logo-header-color.svg';
import pprrLogoHeaderWhite from '../../../assets/images/pprr-logos/pprr-logo-header-white.svg';

const desktopNavbar = ({ menuItems, loading, ctaButtons, theme }) => (
    <div className='bg-gray-50 dark:bg-gray-900 fixed top-0 left-0 w-full z-50'>
        <div className='max-w-7xl mx-auto px-4'>
            <div className='flex items-center justify-between h-16'>
                <div className='flex items-center justify-start'>
                    {/* Logo */}
                    <Link to='/'>
                        <img
                            className='block h-10 w-auto'
                            src={
                                theme === 'dark'
                                    ? pprrLogoHeaderWhite
                                    : pprrLogoHeaderColor
                            }
                            alt='PPRR Logo'
                        />
                    </Link>
                    {/* Desktop menu items */}
                    <div className='ml-4 flex items-center space-x-1 lg:grid-flow-row'>
                        <DesktopMenuItems
                            menuItems={menuItems}
                            loading={loading}
                        />
                    </div>
                </div>
                {/* CTA Buttons */}
                <div className='space-x-2'>
                    <CtaButtons ctaButtons={ctaButtons} />
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(desktopNavbar);
