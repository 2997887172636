// Libraries
import { Listbox } from '@headlessui/react';

// Services, data, and media
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const Filter = ({ filterText, onFilter, onClear, filterColumns }) => {
    const filters = Object.keys(filterColumns).map((col) => {
        return (
            <div key={col} className='flex items-center mr-4 py-2'>
                <h5 className='font-serif font-semibold'>
                    {filterColumns[col].name.toUpperCase()}
                </h5>
                <div className='relative mx-3'>
                    <Listbox
                        value={filterColumns[col].value}
                        onChange={(value) =>
                            filterColumns[col].setValue(
                                value,
                                filterColumns[col].selector
                            )
                        }
                    >
                        <Listbox.Button className='relative h-10 py-2 pl-3 pr-10 text-left bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md cursor-pointer focus:outline-none'>
                            <span className='block truncate font-sans'>
                                {filterColumns[col].value}
                            </span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <ChevronUpDownIcon
                                    className='w-5 h-5 heroicon-stroke-w-2.5 text-gray-500'
                                    aria-hidden='true'
                                />
                            </span>
                        </Listbox.Button>
                        <Listbox.Options className='absolute w-auto z-50 py-1 mt-1 overflow-auto bg-gray-50 dark:bg-gray-800 rounded-md shadow-lg max-h-60 focus:outline-none'>
                            {filterColumns[col].options.map((option) => (
                                <Listbox.Option
                                    key={option}
                                    value={option}
                                    className={({ active }) =>
                                        `${
                                            active
                                                ? 'bg-gray-200 dark:bg-gray-700'
                                                : ''
                                        }
                                font-sans text-center cursor-pointer select-none relative py-2 pr-10 pl-3 min-h-[2.5rem]`
                                    }
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`${
                                                    selected
                                                        ? 'font-bold'
                                                        : 'font-normal'
                                                } block`}
                                            >
                                                {option}
                                            </span>
                                            {selected ? (
                                                <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                                                    <CheckIcon
                                                        className='w-5 h-5 heroicon-stroke-w-2.5'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Listbox>
                </div>
            </div>
        );
    });

    return (
        <div className='w-full flex flex-wrap justify-between items-center overflow-visible'>
            <div className='w-full md:w-1/4 relative flex items-center py-4'>
                <input
                    className='w-full p-2 px-8 border border-gray-500 rounded-md bg-gray-50 dark:bg-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-light text-base'
                    type='text'
                    placeholder='Search...'
                    value={filterText}
                    onChange={onFilter}
                />
                <MagnifyingGlassIcon className='absolute left-1 h-6 w-6 heroicon-stroke-w-1.5 text-gray-500' />
                {filterText.length > 0 ? (
                    <XMarkIcon
                        className='absolute right-1 h-8 w-8 heroicon-stroke-w-1.5 cursor-pointer text-gray-500'
                        onClick={onClear}
                    />
                ) : null}
            </div>
            <div className='w-full md:w-3/4 flex flex-wrap md:justify-end items-center px-4'>
                {filters}
            </div>
        </div>
    );
};

export default Filter;
