// Libraries
import React, { Component } from 'react';

class EventFormPage extends Component {
    // Note: Use class-based component because it will have state in the future
    // once we add the form functionality
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='w-full p-4 mx-auto prose dark:prose-invert'>
                <h2>Add Event</h2>
                <p>Add your organization's event to our regional calendar!</p>
                <p>
                    To add an event to the calendar, email{' '}
                    <a href='mailto:webmaster@pprrun.org'>
                        webmaster@pprrun.org
                    </a>{' '}
                    with the following event information:
                </p>
                <ul>
                    <li>Name</li>
                    <li>Date &amp; Time</li>
                    <li>Distance / Time / Duration</li>
                    <li>Location</li>
                    <li>Location Link (e.g. Google Maps)</li>
                    <li>Description</li>
                    <li>Website Link and / or Registration Link</li>
                    <li>Any Other Relevant Links</li>
                    <li>Contact Name</li>
                    <li>Contact Email and / or Phone</li>
                </ul>
                <p>
                    We will do our best to review and post your event within one
                    week.
                </p>
            </div>
        );
    }
}

export default EventFormPage;
