// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

// Components
import CourseRecordsDisplay from './CourseRecordsDisplay';
import ListBox from '../../components/ListBox/ListBox';

// Services, data, and media
import {
    getCourseRecords,
    getCourseRecordsPage,
    getPersonalCourseRecords,
} from '../../services/courseRecordsService';
import {
    setCourseRecords,
    setPersonalCourseRecords,
    setScrollPosition,
} from '../../redux/actions/state';
import { isNullUndefined } from '../../utils/general';
const gfm = require('remark-gfm');

class CourseRecordsPage extends Component {
    state = {
        loading: true,
        error: false,
        courseRecordsData: [],
        courseRecordsPageData: null,
        selectedOption: 'Active Courses',
        activeOptions: ['Active Courses', 'Inactive Courses'],
    };

    componentDidMount() {
        let loadFromRedux = false;
        if (this.props.location.state) {
            loadFromRedux = this.props.location.state.loadFromRedux;
            /* Clear state from location history so we get new 
            data when we refresh the page */
            this.props.history.replace({ state: {} });
        }

        if (loadFromRedux) {
            if (this.props.isPersonal) {
                this.setPersonalCourseRecordsFromRedux();
                this.setState({ loading: false }, () => {
                    window.scrollTo({
                        top: this.props.scrollPositionPersonal,
                        left: 0,
                        behavior: 'auto',
                    });
                });
            } else {
                this.setCourseRecordsFromRedux();
                this.getCourseRecordsPageData();
                this.setState({ loading: false }, () => {
                    window.scrollTo({
                        top: this.props.scrollPosition,
                        left: 0,
                        behavior: 'auto',
                    });
                });
            }
        } else {
            this.getCourseRecordsData();
            if (!this.props.isPersonal) {
                this.getCourseRecordsPageData();
            }
            window.scrollTo(0, 0);
        }
    }

    componentDidUpdate(prevProps) {
        /**
         * Handle case when navigating from personal course records page to
         * course records page and we need the course records page data to load
         */
        if (prevProps.isPersonal && !this.props.isPersonal) {
            this.getCourseRecordsPageData();
        }
        if (prevProps.isPersonal !== this.props.isPersonal) {
            this.getCourseRecordsData();
        }
    }

    componentWillUnmount() {
        if (this.props.isPersonal) {
            this.updateReduxPersonalCourseRecords();
            this.props.setScrollPosition(
                window.scrollY,
                'PersonalCourseRecords'
            );
        } else {
            this.updateReduxCourseRecords();
            this.props.setScrollPosition(window.scrollY, 'CourseRecords');
        }
    }

    getCourseRecordsData = () => {
        let active = true;
        if (this.state.selectedOption === 'Inactive Courses') {
            active = false;
        }
        let getFunction = this.props.isPersonal
            ? getPersonalCourseRecords
            : getCourseRecords;
        getFunction(active)
            .then((response) => {
                this.setState({
                    loading: false,
                    courseRecordsData: response.data.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    };

    getCourseRecordsPageData = () => {
        getCourseRecordsPage().then((response) => {
            this.setState({
                courseRecordsPageData: response.data.data,
            });
        });
    };

    setSelectedOption = (option) => {
        this.setState(
            { selectedOption: option, loading: true },
            this.getCourseRecordsData
        );
    };

    setCourseRecordsFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                courseRecordsData: this.props.reduxCourseRecords,
            };
        });
    };

    setPersonalCourseRecordsFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                courseRecordsData: this.props.reduxPersonalCourseRecords,
            };
        });
    };

    updateReduxCourseRecords = () => {
        const { courseRecordsData } = this.state;
        this.props.setCourseRecords(courseRecordsData);
    };

    updateReduxPersonalCourseRecords = () => {
        const { courseRecordsData } = this.state;
        this.props.setPersonalCourseRecords(courseRecordsData);
    };

    render() {
        const {
            loading,
            error,
            courseRecordsData,
            courseRecordsPageData,
            selectedOption,
            activeOptions,
        } = this.state;

        const { isPersonal } = this.props;

        const courseRecordsNotes = courseRecordsPageData?.attributes?.notes;

        return (
            <>
                <div className='w-full mx-auto p-4 pb-2 max-w-3xl'>
                    <div className='flex flex-wrap justify-between items-center mb-2'>
                        <h2 className='text-4xl font-bold py-2'>
                            {isPersonal ? 'Personal' : ''} Course Records
                        </h2>
                        <div className='relative w-full sm:w-0 h-14 py-2'>
                            <ListBox
                                options={activeOptions}
                                onChange={this.setSelectedOption}
                                selectedOption={selectedOption}
                                widthClass='w-52'
                            />
                        </div>
                    </div>
                    <hr className='mb-2 border-gray-300' />
                </div>
                <CourseRecordsDisplay
                    isPersonal={isPersonal}
                    loading={loading}
                    error={error}
                    courseRecords={courseRecordsData}
                    selectedOption={selectedOption}
                />
                {!isNullUndefined(courseRecordsNotes) && !isPersonal ? (
                    <div className='w-full mx-auto p-4 max-w-3xl prose dark:prose-invert'>
                        <ReactMarkdown
                            children={courseRecordsNotes}
                            remarkPlugins={[gfm]}
                            linkTarget={(href, children, title) =>
                                href.includes('http') ? '_blank' : null
                            }
                        />
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reduxCourseRecords: state.state.courseRecords,
        reduxPersonalCourseRecords: state.state.personalCourseRecords,
        scrollPosition: state.state.scrollPositionCourseRecords,
        scrollPositionPersonal: state.state.scrollPositionPersonalCourseRecords,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCourseRecords: (courseRecords) =>
            dispatch(setCourseRecords(courseRecords)),
        setPersonalCourseRecords: (personalCourseRecords) =>
            dispatch(setPersonalCourseRecords(personalCourseRecords)),
        setScrollPosition: (scrollPosition, page) =>
            dispatch(setScrollPosition(scrollPosition, page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseRecordsPage);
