// Libraries
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import Layout from './components/Layout/Layout';

// Services, data, and media
import { setInitialTheme } from './redux/actions/state';

const App = ({ setInitialTheme }) => {
    useEffect(() => {
        setInitialTheme();
        // Add event listener to react to changes
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        /**
         * Check for addEventListener method on MediaQueryList object.
         * Older versions of iOS Safari (<=12.5.5) and Internet Explorer use
         * the addListener / removeListener methods instead even though those
         * methods are deprecated.
         */

        if (mediaQuery?.addEventListener) {
            mediaQuery.addEventListener('change', setInitialTheme);
        } else {
            mediaQuery.addListener(setInitialTheme);
        }

        return function cleanUp() {
            const mediaQuery = window.matchMedia(
                '(prefers-color-scheme: dark)'
            );
            if (mediaQuery?.removeEventListener) {
                mediaQuery.removeEventListener('change', setInitialTheme);
            } else {
                mediaQuery.removeListener(setInitialTheme);
            }
        };
    }, [setInitialTheme]);

    return (
        <BrowserRouter>
            <Layout />
        </BrowserRouter>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInitialTheme: () => dispatch(setInitialTheme()),
    };
};

export default connect(null, mapDispatchToProps)(App);
