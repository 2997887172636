// Source: https://stackoverflow.com/a/59989768
import { useState, useEffect } from 'react';

const useElementSize = (myRef) => {
    const [elementWidth, setWidth] = useState(0);
    const [elementHeight, setHeight] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (myRef.current) {
                setWidth(myRef.current.offsetWidth);
                setHeight(myRef.current.offsetHeight);
            }
        };

        // Set initial values
        if (myRef.current) {
            setWidth(myRef.current.offsetWidth);
            setHeight(myRef.current.offsetHeight);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef]);

    return { elementWidth, elementHeight };
};

export default useElementSize;
