/* Boolean trick https://michaeluloth.com/filter-boolean */
const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

/**
 * Shuffles an array in place
 * https://javascript.info/task/shuffle
 * @param {*} array
 */
const shuffleArray = (array) => {
    if (Array.isArray(array)) {
        for (let i = array.length - 1; i > 0; i--) {
            // Get a random index from 0 to i
            let j = Math.floor(Math.random() * (i + 1));
            /**
             * Swap elements array[i] and array[j] using destructuring syntax
             * Equivalent to:
             * let t = array[i];
             * array[i] = array[j];
             * array[j] = t;
             */
            [array[i], array[j]] = [array[j], array[i]];
        }
    } else {
        throw new Error('Paramter is not an array');
    }
};

/**
 * Debounce function to delay search / computation while typing
 * https://medium.com/@gabrielmickey28/using-debounce-with-react-components-f988c28f52c1
 */
const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), delay);
    };
};

/**
 * Determine the appropriate media asset (small, medium, large) based on the
 * width and height of the element. This saves bandwidth by not loading large
 * assets unnecessarily.
 * @param {*} media object that includes metadata about a media asset
 * @param {*} elementWidth width in pixels of the desired element (from ref)
 * @param {*} elementHeight height in pixels of the desired element (from ref)
 * @returns url (as a string) of the appropriate asset
 */
const getMediaUrl = (media, elementWidth, elementHeight) => {
    if (!media?.attributes) {
        return null;
    }
    const { formats, url } = media.attributes;
    const elementDimension = elementWidth >= elementHeight ? 'width' : 'height';
    const elementDimensionValue =
        elementWidth >= elementHeight ? elementWidth : elementHeight;

    /**
     * This comparison works for all four cases:
     * (1) Element container is landscape and asset is landscape
     * (2) Element container is landscape and asset is portrait
     * (3) Element container is portrait and asset is portrait
     * (4) Element container is portrait and asset is landscape
     * We always compare on the element's / container's longest dimension (width
     * or height depending on the orientation).
     * The size of the asset's dimension must be greater than or equal to the
     * element's / container's dimension
     */
    if (formats) {
        if (
            formats.small &&
            formats.small[elementDimension] >= elementDimensionValue
        ) {
            return formats.small.url;
        }
        if (
            formats.medium &&
            formats.medium[elementDimension] >= elementDimensionValue
        ) {
            return formats.medium.url;
        }
        if (
            formats.large &&
            formats.large[elementDimension] >= elementDimensionValue
        ) {
            return formats.large.url;
        }
    }
    return url;
};

/**
 * @param {*} value
 * @returns whether value is undefined or null
 */
const isNullUndefined = (value) => {
    return value === undefined || value === null;
};

module.exports = {
    classNames,
    shuffleArray,
    debounce,
    getMediaUrl,
    isNullUndefined,
};
