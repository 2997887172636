// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import ReactMarkdown from 'react-markdown';

// Components
import DataTableDetail from '../../components/DataTableDetail/DataTableDetail';
import DataTableFilter from '../../components/DataTableFilter/DataTableFilter';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { getEntryList } from '../../services/entryListsService';
import { getFullWrittenDate } from '../../utils/datesAndTimes';
import { customStyles } from '../../utils/dataTablesStyles';
import { generateDataTablesColumns } from '../../utils/dataTablesColumns';
import { isNullUndefined } from '../../utils/general';
const gfm = require('remark-gfm');

class EntryListPage extends Component {
    state = {
        loading: true,
        error: false,
        entryListData: {},
    };

    componentDidMount() {
        this.getEntryListData(this.props.match.params.id);
        window.scrollTo(0, 0);
    }

    getEntryListData(id) {
        getEntryList(id)
            .then((response) => {
                this.setState({
                    loading: false,
                    entryListData: response.data.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    render() {
        const { entryListData, loading, error } = this.state;
        const { theme } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }

        if (error || isNullUndefined(entryListData)) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        const { title, race_date, info_message, sections } =
            entryListData.attributes;

        const finalSections = [];
        sections.forEach((section) => {
            // Update columns object to use selector functions and add styling
            const newColumns = generateDataTablesColumns(
                section.columns,
                section.data
            );

            // Pass columns data so the ResultsDetail component can sort fields by order
            const columnsPropsObj = {
                columns: section.columns,
            };

            if (section.header_name.toLowerCase() === 'entries') {
                // Render the entries first
                finalSections.unshift(
                    <DataTableFilter
                        key={section.header_name}
                        section={section}
                        columns={newColumns}
                        expandableRows={true}
                        expandableRowsComponent={DataTableDetail}
                        expandableRowsComponentProps={columnsPropsObj}
                    />
                );
            } else {
                let pagination = false;
                let paginationPerPage = 50;
                if (section.data.length > 25) {
                    pagination = true;
                }
                finalSections.push(
                    <DataTable
                        key={section.header_name}
                        title={section.header_name}
                        columns={newColumns}
                        data={section.data}
                        pagination={pagination}
                        paginationPerPage={paginationPerPage}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                        customStyles={customStyles}
                        theme={theme === 'dark' ? 'dark' : 'light'}
                        striped
                    />
                );
            }
        });

        return (
            <>
                <div className='w-full mx-auto p-4 max-w-5xl'>
                    <div className='prose dark:prose-invert max-w-5xl'>
                        <h1>{title}</h1>
                        <h3>
                            Race Date:&nbsp;
                            {getFullWrittenDate(race_date)}
                        </h3>
                        {!isNullUndefined(info_message) &&
                        info_message !== '' ? (
                            <ReactMarkdown
                                children={info_message}
                                remarkPlugins={[gfm]}
                                linkTarget={(href, children, title) =>
                                    href.includes('http') ? '_blank' : null
                                }
                            />
                        ) : null}
                    </div>
                </div>
                <div className='w-full mx-auto space-y-2 py-2'>
                    {finalSections}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(EntryListPage);
