const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const dayNamesAbbrev = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

const rootDate = new Date();
const currentYear = rootDate.getFullYear();
const currentMonth = rootDate.getMonth();
// Available results go back to 1994
const availableYears = new Array(currentYear - 1994 + 1)
    .fill()
    .map((_, idx) => currentYear - idx);

/**
 * Given a date in ISO format (YYYY-MM-DD) return a date string in the format
 * Month Day, Year
 * Ex: 2022-01-01 returns "January 1, 2022"
 *
 * @param {*} ISODate
 * @returns date string
 */
const getFullWrittenDate = (ISODate) => {
    const dateObj = new Date(ISODate);
    return `${
        monthNames[dateObj.getUTCMonth()]
    } ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`;
};

/**
 * Given a date in ISO format (YYYY-MM-DD) return the month name
 * Ex: 2021-01-01 returns "January"
 *
 * @param {*} ISODate
 * @returns month name
 */
const getMonthName = (ISODate) => {
    const dateObj = new Date(ISODate);
    return monthNames[dateObj.getUTCMonth()];
};

/**
 * Given a date in ISO format (YYYY-MM-DD) return the year
 * Ex: 2021-01-01 returns 2021
 *
 * @param {*} ISODate
 * @returns year
 */
const getYear = (ISODate) => {
    const dateObj = new Date(ISODate);
    return dateObj.getUTCFullYear();
};

/**
 * Return the current local date in ISO format
 * Ex: 2022-01-01
 *
 * @returns current date
 */
const getISODate = () => {
    const date = new Date();
    const timezoneOffsetMs = date.getTimezoneOffset() * 60000;
    // Create a date object whose (internal) UTC time represents local time to
    // get the right date when using toISOString()
    const localDate = new Date(date - timezoneOffsetMs);
    const localDateISO = localDate.toISOString();
    return localDateISO.split('T')[0];
};

/**
 * Given a number of days return an ISO date X days in the future
 *
 * @param {*} days
 * @returns ISO date X days in the future
 */
const getFutureISODate = (days) => {
    // Convert days to milliseconds
    const diffMs = days * 24 * 60 * 60 * 1000;
    const futureDate = new Date(Date.now() + diffMs);
    const futureDateISO = futureDate.toISOString();
    return futureDateISO.split('T')[0];
};

/**
 * Given two date objects, determine the difference in number of days
 *
 * @param {*} dateA
 * @param {*} dateB
 * @returns number of days between dateA and dateB
 */
const getDateDifference = (dateA, dateB) => {
    const diffMs = dateA - dateB;
    // Convert milliseconds to days
    return diffMs / (1000 * 60 * 60 * 24);
};

/**
 * Given a date object, return a pretty printed string
 *
 * @param {*} dateObj
 * @returns pretty printed date, e.g. 10:00AM Thursday, January 13, 2022
 */
const prettyDateTime = (dateObj) => {
    const day = dayNames[dateObj.getDay()];
    const month = monthNames[dateObj.getMonth()];
    const date = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours());
    const minutes = String(dateObj.getMinutes());
    const time = prettyTime(hours, minutes);

    return `${time} ${day}, ${month} ${date}, ${year}`;
};

/**
 * Given a date object, return a pretty printed string for the calendar
 *
 * @param {*} dateObj
 * @returns pretty printed date, e.g. Sat, 13 @ 10:00AM
 */
const prettyDateTimeForCalendar = (dateObj) => {
    const day = dayNamesAbbrev[dateObj.getDay()];
    let date = String(dateObj.getDate());
    const lastDateDigit = date.slice(-1);
    if (lastDateDigit === '1' && date !== '11') {
        date += 'st';
    } else if (lastDateDigit === '2' && date !== '12') {
        date += 'nd';
    } else if (lastDateDigit === '3' && date !== '13') {
        date += 'rd';
    } else {
        date += 'th';
    }

    const hours = String(dateObj.getHours());
    const minutes = String(dateObj.getMinutes());
    const time = prettyTime(hours, minutes);

    return `${day}, ${date} @ ${time}`;
};

/**
 * Given a date object, return a pretty printed string for the calendar
 *
 * @param {*} hours hours returned by the Date Object
 * @param {*} minutes minutes returned by the Date Object
 * @returns pretty printed date, e.g. Sat, 13 @ 10:00AM
 */
const prettyTime = (hours, minutes) => {
    // Add leading 0 to minutes
    if (minutes.length === 1) {
        minutes = '0' + minutes;
    }

    if (hours === '0') {
        hours = '12';
        minutes = minutes + 'am';
    } else if (Number(hours) > 12) {
        // convert 24-hour time to 12-hour time
        hours = String(Number(hours) - 12);
        minutes = minutes + 'pm';
    } else if (hours === '12') {
        minutes = minutes + 'pm';
    } else {
        minutes = minutes + 'am';
    }

    let time = hours + ':' + minutes;

    if (time[0] === '0') {
        // remove leading 0
        time = time.slice(1);
    }

    return time;
};

/**
 * Given a time string, return a pretty printed string
 *
 * @param {*} timeStr time with hours, minutes, seconds, and milliseconds (e.g. 06:00:00.000)
 * @returns pretty printed date, e.g. 6:00 AM
 */
const prettyTimeString = (timeStr) => {
    // Remove seconds and milliseconds
    // timeStrArr = ['06', '00', '00.000']
    const timeStrArr = timeStr.split(':');
    let hours = timeStrArr[0];
    let minutes = timeStrArr[1];

    if (hours === '00') {
        hours = '12';
        minutes = minutes + 'am';
    } else if (Number(hours) > 12) {
        // convert 24-hour time to 12-hour time
        hours = String(Number(hours) - 12);
        minutes = minutes + 'pm';
    } else if (hours === '12') {
        minutes = minutes + 'pm';
    } else {
        minutes = minutes + 'am';
    }

    let time = hours + ':' + minutes;

    if (time[0] === '0') {
        // remove leading 0
        time = time.slice(1);
    }

    return time;
};

module.exports = {
    monthNames,
    dayNames,
    currentYear,
    currentMonth,
    availableYears,
    getFullWrittenDate,
    getMonthName,
    getYear,
    getISODate,
    getFutureISODate,
    getDateDifference,
    prettyDateTime,
    prettyDateTimeForCalendar,
    prettyTimeString,
};
