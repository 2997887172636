const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getHomePageData = () => {
    const query = qs.stringify({
        populate: {
            information: {
                populate: '*',
            },
            hero_media: '*',
            hero_media_large: '*',
        },
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/home-page?${query}`,
    });
};

const getHomePageCarouselItems = (date) => {
    const query = qs.stringify({
        filters: {
            start_date: {
                $lte: date,
            },
            end_date: {
                $gte: date,
            },
        },
        sort: ['end_date:asc'],
        populate: '*',
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/home-page-carousel-items?${query}`,
    });
};

module.exports = {
    getHomePageData,
    getHomePageCarouselItems,
};
