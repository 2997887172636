// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import { Disclosure } from '@headlessui/react';
import CtaButtons from '../CtaButtons/CtaButtons';
import MobileMenuItems from '../MobileMenuItems/MobileMenuItems';

// Services, data, and media
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import pprrLogoHeaderColor from '../../../assets/images/pprr-logos/pprr-logo-header-color.svg';
import pprrLogoHeaderWhite from '../../../assets/images/pprr-logos/pprr-logo-header-white.svg';

class MobileNavbar extends Component {
    state = {
        disclosureOpen: false,
    };

    // Handle disclosure visibility to close mobile menu when a menu option is selected
    toggleDisclosure = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                disclosureOpen: !prevState.disclosureOpen,
            };
        });
    };

    render() {
        const { disclosureOpen } = this.state;
        return (
            <Disclosure
                as='nav'
                className='bg-gray-50 dark:bg-gray-900 fixed top-0 left-0 w-full z-50'
            >
                <>
                    {/* Mobile header */}
                    <div className='px-4 sm:px-6'>
                        <div className='flex items-center justify-between h-16'>
                            {/* Logo */}
                            <Link
                                to='/'
                                onClick={
                                    disclosureOpen
                                        ? this.toggleDisclosure
                                        : null
                                }
                            >
                                <img
                                    className='block h-10 w-auto'
                                    src={
                                        this.props.theme === 'dark'
                                            ? pprrLogoHeaderWhite
                                            : pprrLogoHeaderColor
                                    }
                                    alt='PPRR Logo'
                                />
                            </Link>
                            {/* Hamburger button */}
                            <div onClick={this.toggleDisclosure}>
                                <Disclosure.Button className='p-2 rounded-md text-gray-900 dark:text-gray-50 hover:text-gray-50 dark:hover:text-gray-900 hover:bg-gray-900 dark:hover:bg-gray-50'>
                                    <span className='sr-only'>
                                        Open main menu
                                    </span>
                                    {disclosureOpen ? (
                                        <XMarkIcon
                                            className='block h-8 w-8 heroicon-stroke-w-1.5'
                                            aria-hidden='true'
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className='block h-8 w-8 heroicon-stroke-w-1.5'
                                            aria-hidden='true'
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    {/* Mobile menu */}
                    {disclosureOpen ? (
                        <Disclosure.Panel static>
                            <div className='p-2 mobile-menu overflow-scroll'>
                                {/* CTA Buttons */}
                                <div className='py-2 flex justify-center space-x-2'>
                                    <CtaButtons
                                        ctaButtons={this.props.ctaButtons}
                                        toggleDisclosure={this.toggleDisclosure}
                                    />
                                </div>
                                <div className='space-y-1'>
                                    <MobileMenuItems
                                        loading={this.props.loading}
                                        menuItems={this.props.menuItems}
                                        toggleDisclosure={this.toggleDisclosure}
                                    />
                                </div>
                            </div>
                        </Disclosure.Panel>
                    ) : null}
                </>
            </Disclosure>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(MobileNavbar);
