import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { StarIcon, BoltIcon, TrophyIcon } from '@heroicons/react/24/solid';

const EventTypeIcons = ({
    clubEvent,
    clubAssistedEvent,
    communityCupEvent,
}) => {
    if (!clubEvent && !clubAssistedEvent && !communityCupEvent) {
        return null;
    }

    return (
        <div className='flex space-x-2 mx-2'>
            {clubEvent ? (
                <Tippy content='Club Event' placement='right' delay={300}>
                    <StarIcon
                        className='h-6 w-6 text-green outline-none'
                        aria-hidden='true'
                    />
                </Tippy>
            ) : null}
            {clubAssistedEvent ? (
                <Tippy
                    content='Club Assisted Event'
                    placement='right'
                    delay={300}
                >
                    <BoltIcon
                        className='h-6 w-6 text-red outline-none'
                        aria-hidden='true'
                    />
                </Tippy>
            ) : null}
            {communityCupEvent ? (
                <Tippy
                    content='Community Cup Event'
                    placement='right'
                    delay={300}
                >
                    <TrophyIcon
                        className='h-6 w-6 text-blue dark:text-yellow outline-none'
                        aria-hidden='true'
                    />
                </Tippy>
            ) : null}
        </div>
    );
};

export default EventTypeIcons;
